import React, { useEffect, useState } from "react";
import img from "../../Assets/Images/image 48.png";
import "./BadCredit.css";
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const BadCredit = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const[index,setIndex]=useState(0)

  // Function to check if the device is mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile devices are considered to be 768px or below
  };

  useEffect(() => {
    // Check screen size on component mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const [openIndex2, setOpenIndex2] = useState(null);

  const toggleAccordion2 = (index) => {
    setOpenIndex2(openIndex2 === index ? null : index);
  };
  return (
    <div>
      <div className="container-fluid"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "5%",
          paddingRight: "5%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Best Bad Credit Loans of October 2024
        </div>
        <div>
      <p
        style={{
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1.2,
        }}
      >
        {/* Show full text if not on mobile, otherwise apply 'Read More' functionality */}
        {!isMobile || isExpanded ? (
          <>
            Finding a loan with bad Credit can be challenging, but it’s not
            impossible. The best bad Credit loans of October 2024 offer flexible{" "}
            terms, lower interest rates, and fast approval processes for those who
            may not have a high Credit score. Whether you're looking to
            consolidate debt, cover emergency expenses, or finance a major
            purchase, these loans provide viable solutions to meet your needs.
            Our top picks focus on lenders who understand your situation and
            offer more accessible options. Explore these options to find the best
            loan tailored to your financial situation today.
          </>
        ) : (
          <>
            Finding a loan with bad Credit can be challenging, but it’s not
            impossible. The best bad Credit loans of October 2024 offer flexible{" "}
            terms, lower interest rates, and fast approval processes... <span  style={{backgroundColor:'transparent',color:"#59266D"}} onClick={toggleReadMore}> {isExpanded ? "Show Less" : "Read More"}</span> 
          </>
        )}
      </p>

    </div>
      </div>
      <div  className="container" style={{marginLeft:'5%',marginRight:'5%',marginTop: 56 }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Suggested loans for bad Credit.
        </p>
        <p
          style={{
            fontWeight: 400,
            fontSize: 16,
            color: "#8A8A8A",
            marginTop: 8,
          }}
        >
          MinEMI suggests the best loans for bad Credit scores by analyzing
          thousands of companies
        </p>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 24,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 14,
              marginLeft: "1%",
              paddingBottom: 0,
            }}
          >
            Best bad Credit loans for thin Credit
          </p>
          <div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display:'flex',
              flexDirection:'column',gap:8
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Est. APR
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      9.99 - 40%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Min. Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      600
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Repayment terms
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      2 to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div onClick={()=>{if(index===1){setIndex(0)}
              else{
               setIndex(1)
              }
               } }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                View Details
              </div>
            </div>
            {index==1 &&
              <div style={{backgroundColor:'white'}}>
              <div
                className="accordion "
                style={{ marginTop: 9, backgroundColor: "white" }}
              >
                {[...Array(3)].map((_, index) => (
                  <div
                    className="accordion-item"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          backgroundColor: "white",
                        }}
                      >
                        Accordion Item #{index + 1}
                        {openIndex === index ? (
                          <FaChevronUp style={{ backgroundColor: "white" }} />
                        ) : (
                          <FaAngleDown
                            style={{
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          />
                        )}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse ${
                        openIndex === index ? "show" : ""
                      }`}
                    >
                      <div
                        className="accordion-body"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          backgroundColor: "white",
                        }}
                      >
                        Placeholder content for this accordion, which is
                        intended to demonstrate the accordion class. This is the{" "}
                        {index + 1} item's accordion body.
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
            
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }} onClick={()=>{if(index===1){setIndex(0)}
              else{
               setIndex(2)
              }
               } }>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Est. APR
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      9.99 - 40%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Min. Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      600
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Repayment terms
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      2 to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                View Details
              </div>
            </div>
            {index==2 &&
              <div style={{backgroundColor:'white'}}>
              <div
                className="accordion "
                style={{ marginTop: 9, backgroundColor: "white" }}
              >
                {[...Array(3)].map((_, index) => (
                  <div
                    className="accordion-item"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          backgroundColor: "white",
                        }}
                      >
                        Accordion Item #{index + 1}
                        {openIndex === index ? (
                          <FaChevronUp style={{ backgroundColor: "white" }} />
                        ) : (
                          <FaAngleDown
                            style={{
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          />
                        )}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse ${
                        openIndex === index ? "show" : ""
                      }`}
                    >
                      <div
                        className="accordion-body"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          backgroundColor: "white",
                        }}
                      >
                        Placeholder content for this accordion, which is
                        intended to demonstrate the accordion class. This is the{" "}
                        {index + 1} item's accordion body.
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}onClick={()=>{if(index===1){setIndex(0)}
              else{
               setIndex(3)
              }
               } }>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Est. APR
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      9.99 - 40%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Min. Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      600
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Repayment terms
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      2 to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                View Details
              </div>
            </div>
            {index==3 &&
              <div style={{backgroundColor:'white'}}>
              <div
                className="accordion "
                style={{ marginTop: 9, backgroundColor: "white" }}
              >
                {[...Array(3)].map((_, index) => (
                  <div
                    className="accordion-item"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          backgroundColor: "white",
                        }}
                      >
                        Accordion Item #{index + 1}
                        {openIndex === index ? (
                          <FaChevronUp style={{ backgroundColor: "white" }} />
                        ) : (
                          <FaAngleDown
                            style={{
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          />
                        )}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse ${
                        openIndex === index ? "show" : ""
                      }`}
                    >
                      <div
                        className="accordion-body"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          backgroundColor: "white",
                        }}
                      >
                        Placeholder content for this accordion, which is
                        intended to demonstrate the accordion class. This is the{" "}
                        {index + 1} item's accordion body.
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}  onClick={()=>{if(index===1){setIndex(0)}
              else{
               setIndex(4)
              }
               } }>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Est. APR
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      9.99 - 40%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Min. Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      600
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Repayment terms
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      2 to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                View Details
              </div>
            </div>
            {index==4 &&
              <div style={{backgroundColor:'white'}}>
              <div
                className="accordion "
                style={{ marginTop: 9, backgroundColor: "white" }}
              >
                {[...Array(3)].map((_, index) => (
                  <div
                    className="accordion-item"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          backgroundColor: "white",
                        }}
                      >
                        Accordion Item #{index + 1}
                        {openIndex === index ? (
                          <FaChevronUp style={{ backgroundColor: "white" }} />
                        ) : (
                          <FaAngleDown
                            style={{
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          />
                        )}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse ${
                        openIndex === index ? "show" : ""
                      }`}
                    >
                      <div
                        className="accordion-body"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          backgroundColor: "white",
                        }}
                      >
                        Placeholder content for this accordion, which is
                        intended to demonstrate the accordion class. This is the{" "}
                        {index + 1} item's accordion body.
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
               <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}  onClick={()=>{if(index===1){setIndex(0)}
              else{
               setIndex(5)
              }
               } }>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Est. APR
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      9.99 - 40%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Loan Amount
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Min. Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      600
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:"center",
                      gap: 8,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Repayment terms
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      2 to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                View Details
              </div>
            </div>
            {index==5 &&
              <div style={{backgroundColor:'white'}}>
              <div
                className="accordion "
                style={{ marginTop: 9, backgroundColor: "white" }}
              >
                {[...Array(3)].map((_, index) => (
                  <div
                    className="accordion-item"
                    key={index}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openIndex === index ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          backgroundColor: "white",
                        }}
                      >
                        Accordion Item #{index + 1}
                        {openIndex === index ? (
                          <FaChevronUp style={{ backgroundColor: "white" }} />
                        ) : (
                          <FaAngleDown
                            style={{
                              backgroundColor: "white",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          />
                        )}
                      </button>
                    </h2>
                    <div
                      className={`accordion-collapse ${
                        openIndex === index ? "show" : ""
                      }`}
                    >
                      <div
                        className="accordion-body"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          backgroundColor: "white",
                        }}
                      >
                        Placeholder content for this accordion, which is
                        intended to demonstrate the accordion class. This is the{" "}
                        {index + 1} item's accordion body.
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            }
         
          
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Compare Loans for Bad Credit
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          When comparing loans for bad Credit, it's essential to look beyond
          just the interest rate. Here are key factors to consider:
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Interest Rates :
            </span>{" "}
            Bad Credit loans often come with higher interest rates, so it's
            important to compare multiple lenders to find the most affordable
            rate.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2.Fees and Charges :
            </span>{" "}
            Check for hidden fees such as origination fees, late payment fees,
            or early repayment penalties, which can increase the overall cost of
            the loan.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.Loan Terms :
            </span>{" "}
            Review the repayment period. Longer terms may mean lower monthly
            payments, but you'll pay more in interest over time. A shorter term
            could save money but requires higher payments.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Approval Time :
            </span>{" "}
            For urgent needs, consider how fast the lender processes loan
            applications and disburses funds. Some lenders offer quick approval
            and same-day funding.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              5.Loan Fixed :
            </span>{" "}
            Some lenders offer more flexible repayment options, such as deferred
            payments or the ability to adjust your payment schedule. Look for
            terms that fit your financial situation.
          </div>
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Bad Credit loan interest rates and fees
        </div>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            border: "1px solid #D9D9D9",
            marginTop: 16,
          }}
        >
          <thead>
            <tr style={{ border: "1px solid #D9D9D9", padding: 4 }}>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Borrow Credit rating
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Score range
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Estimated APR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Excellent
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                720 - 850
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                11%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Good
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                690 - 718
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                15%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Fair
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Thornton
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                18%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Bad
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                300 - 629
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                22%
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Get a Personal Loan for Bad Credit
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Securing a personal loan with bad credit can be challenging, but it's
          not impossible. Here’s a step-by-step guide to help you get started:
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Check Your Credit Score :
            </span>{" "}
            Begin by reviewing your credit score. Knowing where you stand helps
            you understand what loan options are available to you and what
            lenders will likely approve your application.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2.Explore Lenders for Bad Credit:
            </span>{" "}
            Look for lenders that specialize in bad credit loans. These lenders
            are more willing to work with borrowers who have less-than-perfect
            credit and may offer more flexible terms.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.Consider a Co-signer :
            </span>{" "}
            If your credit score is too low, having a co-signer with good credit
            can increase your chances of approval and might help you secure a
            better interest rate.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Prequalify Before Applying :
            </span>{" "}
            Many lenders allow you to prequalify for a loan without affecting
            your credit score. This helps you see what interest rates and terms
            you qualify for before formally applying.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              5.Compare Loan Options :
            </span>{" "}
            Compare Loan Options: Once you have multiple offers, compare
            interest rates, fees, loan amounts, and repayment terms. Choose the
            option that best fits your financial situation and repayment
            ability.
          </div>
        </div>
        <div
          style={{
            marginTop: 40,
            marginBottom: 24,
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs)
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            marginBottom: 120,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",}}
            >
              Accordion 1
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default BadCredit;
