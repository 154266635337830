import React, { useState, useRef, useEffect } from "react";
import Vector from "../../Assets/Images/Vector.png";
import "./Form.css";
import { useNavigate, useLocation } from "react-router-dom";
import check from "../../Assets/Images/check.png";
import uncheck from "../../Assets/Images/uncheck.png";
import axios from "axios";
function Form2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loanAmount, oldIrr, tenure, newIrr, accountType, Account_Number } =
    location.state;
  const [outstanding, setoutStanding] = useState(loanAmount);
  const [oldIrrrs, seOldir] = useState(oldIrr);
  const [tenures, setTenure] = useState(tenure);
  const [newIrrs, setNewirrs] = useState(newIrr);
  const [salary, setSalary] = useState("");
  const [Address, setAddress] = useState("");
  const [workaddress, setWorkAddress] = useState("");
  const [Property, setProperty] = useState("");
  const [market, setMarket] = useState("");
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [option, setoption] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [click, setClick] = useState(false);

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const formData = new FormData();
  const [Residential, setResidential] = useState();
  const id = localStorage.getItem("id");
  const HandleImageClick = () => {
    inputRef.current.click();
  };
  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage(file.name);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
  };
  const HandleImageClick1 = () => {
    inputRef1.current.click();
  };
  const handleChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage1(file.name);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
  };
  formData.append("goal", id);
  formData.append("account_Type", accountType);
  formData.append("uploadSalarySlip", image);
  formData.append("accountNumber", Account_Number);
  formData.append("permanentAddress", Address);
  formData.append("BankStatement", image1);
  formData.append("monthlyIncome", salary);
  formData.append("workAddress", workaddress);
  formData.append("outstandingLoanAmount", outstanding);
  formData.append("currentInterestRate", oldIrrrs);
  formData.append("remainingTenure", tenures);
  formData.append("newInterestRateOffered", newIrrs);
  const HandleUpdate = () => {
    setClick(true);
    if (
      outstanding === "" ||
      isNaN(outstanding) ||
      oldIrrrs === "" ||
      isNaN(oldIrrrs) ||
      tenures === "" ||
      isNaN(tenures) ||
      newIrrs === "" ||
      isNaN(newIrrs) ||
      Residential === null ||
      market === "" ||
      isNaN(market) ||
      salary === "" ||
      isNaN(salary) ||
      Address === "" ||
      workaddress === "" ||
      !isNaN(workaddress) ||
      image === null ||
      image1 === null ||
      newIrrs == "" ||
      oldIrrrs == "" ||
      Residential == null ||
      Property == null
    ) {
      if (Property === "" || !isNaN(Property)) {
        setError(true);
      }
      if (image === null) {
        setImage(null);
      }
      if (image1 === null) {
        setImage1(null);
      }
      if (market === "" || isNaN(market)) {
        setError1(true);
      }
      if (workaddress === "" || !isNaN(workaddress)) {
        setError4(true);
      }
      if (salary === "" || isNaN(salary)) {
        setError2(true);
      }
      if (Address === "" || !isNaN(Address)) {
        setError2(true);
      }
    } else {
      const token = localStorage.getItem("token");
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SECRET_KEY}/user/applyLoan`,
        headers: {
          Authorization: `Bearer ${token} `,
        },
        data: formData,
      };
      axios
        .request(options)
        .then(function (response) {
          navigate("/confirm");
        })
        .catch(function (error) {
          // console.error(error);
        });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
    }
  }, []);

  return (
    <>
      <div
        className="form-heading"
        style={{
          display: "flex",
          marginTop: "60px",
          marginBottom: "60px",
          marginLeft: "90px",
          flexDirection: "column",
        }}
      >
        <div
          className="form-heading"
          style={{
            display: "flex",
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "35px",
            letterSpacing: "0em",
            color: "#59266D",
          }}
        >
          Fill Your Details
        </div>
        <div
          className="form-heading"
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#8A8A8A",
            marginTop: "10px",
          }}
        >
          Please provide the following details to confirm your{" "}
          <br className="breaker1" /> application
        </div>
      </div>
      <div
        className="main-profile-box"
        style={{
          display: "flex",
          gap: "24px",
          flexWrap: "wrap",
          marginLeft: "90px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Outstanding Loan Amount
          </p>
          <input
            className="Form-field"
            value={outstanding}
            onChange={(e) => {
              const value = e.target.value;
              // Remove any non-numeric characters except '.'
              const filteredValue = value.replace(/[^\d]/g, "");

              // Ensure the number doesn't start with 0
              if (filteredValue.startsWith("0")) {
                setoutStanding(filteredValue.slice(1)); // Remove the leading zero
              } else {
                setoutStanding(filteredValue);
              }
            }}
            type="text"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {outstanding === "" || isNaN(outstanding) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Outstanding Loan Amount is required
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Current Interest Rate(Prefilled)
          </p>
          <input
            className="Form-field"
            value={oldIrrrs}
            onChange={(e) => {
              const value = e.target.value;
              // Remove any non-numeric characters except '.' and make sure only one decimal point is allowed
              const filteredValue = value
                .replace(/[^\d.]/g, "")
                .replace(/(\..*)\./g, "$1");

              // Ensure the number doesn't start with 0 unless it's "0."
              if (filteredValue.startsWith("0") && filteredValue !== "0.") {
                seOldir(filteredValue.slice(1)); // Remove the leading zero
              } else {
                seOldir(filteredValue);
              }
            }}
            type="email"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {oldIrrrs == "" || isNaN(oldIrrrs) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Current Interest Rate is required
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Remaining Tenure(Prefilled)
          </p>
          <input
            className="Form-field"
            type="email"
            value={tenures}
            onChange={(e) => {
              const value = e.target.value;
              // Remove any non-numeric characters except '.'
              const filteredValue = value.replace(/[^\d]/g, "");

              // Ensure the number doesn't start with 0
              if (filteredValue.startsWith("0")) {
                setTenure(filteredValue.slice(1)); // Remove the leading zero
              } else {
                setTenure(filteredValue);
              }
            }}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {tenures == "" || isNaN(tenures) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Remaining Tenure is required
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            New Interest Rate offered
          </p>
          <input
            className="Form-field"
            type="email"
            value={newIrrs}
            onChange={(e) => {
              const value = e.target.value;
              // Remove any non-numeric characters except '.' and make sure only one decimal point is allowed
              const filteredValue = value
                .replace(/[^\d.]/g, "")
                .replace(/(\..*)\./g, "$1");

              // Ensure the number doesn't start with 0 unless it's "0."
              if (filteredValue.startsWith("0") && filteredValue !== "0.") {
                setNewirrs(filteredValue.slice(1)); // Remove the leading zero
              } else {
                setNewirrs(filteredValue);
              }
            }}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {newIrrs == "" || isNaN(newIrrs) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              New Interest Rate is required
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="file-field"
        style={{
          display: "flex",
          marginTop: "40px",
          gap: "36px",
          marginBottom: "60px",
          flexWrap: "wrap",
          marginLeft: "90px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Upload Salary Slips(s)
          </p>
          <div
            className="upload-box"
            style={{
              border: "1px dashed #8A8A8A",
              width: "378px",
              height: "104px",
              padding: "16px, 23px, 24px, 23px",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {image == null ? (
              <>
                <div style={{ backgroundColor: "transparent" }}>
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleChange}
                    accept=".jpg, .jpeg, .png, .pdf" // Add this line to accept only jpg, jpeg, and png files
                  />
                  <img
                    onClick={HandleImageClick}
                    src={Vector}
                    alt=""
                    width="21px"
                    height="16px"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Upload Salary Slips(s)
                </div>
              </>
            ) : (
              <div style={{ backgroundColor: "transparent" }}>
                <div
                  style={{ display: "flex", backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>Name:</div>
                  <div style={{ backgroundColor: "transparent" }}>{image}</div>
                </div>
              </div>
            )}
          </div>
          {image == null ? (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Upload Your Salary Slip
            </div>
          ) : null}

          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#8A8A8A",
              marginTop: "10px",
            }}
          >
            Last 3 months salary slip <br />
            File Format:JPG,PNG,PDF
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Upload Bank Statements(s)
          </p>
          <div
            className="upload-box"
            style={{
              border: "1px dashed #8A8A8A",
              width: "378px",
              height: "104px",
              padding: "16px, 23px, 24px, 23px",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {image1 == null ? (
              <>
                <div style={{ backgroundColor: "transparent" }}>
                  <input
                    type="file"
                    ref={inputRef1}
                    style={{ display: "none" }}
                    onChange={handleChange1}
                    accept=".jpg, .jpeg, .png .pdf"
                  />
                  <img
                    onClick={HandleImageClick1}
                    src={Vector}
                    alt=""
                    width="21px"
                    height="16px"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Upload Bank Statement(s)
                </div>
              </>
            ) : (
              <div style={{ backgroundColor: "transparent" }}>
                <div
                  style={{ display: "flex", backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>Name:</div>
                  <div style={{ backgroundColor: "transparent" }}>{image1}</div>
                </div>
              </div>
            )}
          </div>
          {image1 == null ? (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Upload Your Salary Slip
            </div>
          ) : null}

          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#8A8A8A",
              marginTop: "10px",
            }}
          >
            Last 3 months bank statement <br />
            File Format:JPG,PNG,PDF
          </div>
        </div>
      </div>
      <div
        className="main-profile-box"
        style={{
          display: "flex",
          gap: "24px",
          marginLeft: "90px",
          marginBottom: "30px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Property Type (Residential,Commercial)
          </p>
          <input
            readOnly
            className="Form-field"
            value={Residential}
            type="text"
            placeholder="Residential/Commercial"
            onClick={() => {
              setoption(!option);
            }}
            onChange={(e) => setResidential(e.target.value)}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
        </div>
        {option && (
          <div className="Form-option-field">
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: "transparent",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={Residential == "Residential" ? check : uncheck}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "13px",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  setResidential("Residential");
                  setoption(false);
                }}
              >
                Residential
              </div>
            </div>
            <div
              style={{
                paddingBottom: "10px",
                backgroundColor: "transparent",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={Residential == "Commerical" ? check : uncheck}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "14px",
                  marginLeft: "13px",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  setResidential("Commerical");
                  setoption(false);
                }}
              >
                Commercial
              </div>
            </div>
          </div>
        )}
        <div>
          {click && Residential == null ? (
            <div
              style={{ color: "red", marginTop: "-15px", marginBottom: "15px" }}
            >
              Please select Property Type
            </div>
          ) : null}
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Property Location (Address)
          </p>
          <input
            className="Form-field"
            onChange={(e) => {
              const value = e.target.value;
              setProperty(value);
              if (/^[a-zA-Z0-9]*$/.test(value)) {
                setError(false);
              } else {
                setError(true);
              }
            }}
            onBlur={() => {
              if (Property === "" || !/^[a-zA-Z0-9]*$/.test(Property)) {
                setError(true);
              } else {
                setError(false);
              }
            }}
            type="text"
            placeholder=" Property Location"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Enter Valid Detail
            </div>
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Estimated Market Value
          </p>
          <input
            className="Form-field"
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              setMarket(value);
              if (/^[1-9][0-9]*$/.test(value)) {
                setError1(false);
              } else {
                setError1(true);
              }
            }}
            onBlur={() => {
              if (/^[1-9][0-9]*$/.test(market)) {
                setError1(false);
              } else {
                setError1(true);
              }
            }}
            placeholder="Estimated Market Value"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error1 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please enter valid market value
            </div>
          )}
        </div>
      </div>
      <div
        className="main-profile-box"
        style={{
          display: "flex",
          gap: "24px",
          marginLeft: "90px",
          marginBottom: "40px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Monthly Income/Salary
          </p>
          <input
            className="Form-field"
            type="text"
            placeholder="Monthly Income/Salary"
            onChange={(e) => {
              const value = e.target.value.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
              setSalary(value);
              if (/^[1-9][0-9]*$/.test(value)) {
                setError2(false);
              } else {
                setError2(true);
              }
            }}
            onBlur={() => {
              if (/^[1-9][0-9]*$/.test(salary)) {
                setError2(false);
              } else {
                setError2(true);
              }
            }}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error2 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please enter valid
            </div>
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Permanent Address
          </p>
          <input
            className="Form-field"
            onChange={(e) => {
              const value = e.target.value;
              setAddress(value);
              if (/^[a-zA-Z0-9]*$/.test(value)) {
                setError3(false);
              } else {
                setError3(true);
              }
            }}
            onBlur={() => {
              if (Address === "" || !/^[a-zA-Z0-9]*$/.test(Address)) {
                setError3(true);
              } else {
                setError3(false);
              }
            }}
            type="text"
            placeholder="Permanent Address"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error3 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Enter Valid Permanent Address
            </div>
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Work Address
          </p>
          <input
            className="Form-field"
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              setWorkAddress(value);
              if (/^[a-zA-Z0-9\s]*$/.test(value)) {
                setError4(false);
              } else {
                setError4(true);
              }
            }}
            onBlur={() => {
              if (workaddress === "" || !/^[a-zA-Z0-9\s]*$/.test(workaddress)) {
                setError4(true);
              } else {
                setError4(false);
              }
            }}
            placeholder="Work Address"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error4 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Enter Valid Work Address
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "60px",
        }}
      >
        <button
          onClick={HandleUpdate}
          className="Form-fields"
          style={{
            width: "378px",
            height: "48px",
            borderRadius: "10px",
            gap: "8px",
            backgroundColor: "#59266D",
            cursor: "pointer",
            border: "none",
            outline: "none",
            color: "#FFFFFF",
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default Form2;
