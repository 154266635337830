import React from 'react'
import { Accordion, Panel } from 'rsuite';
import "rsuite/dist/rsuite.css";
import { FaSortDown } from 'react-icons/fa';


const Faq = () => {
  return (
    <>
    <div className='container p-4'>
        <p style={{textAlign:'center',fontSize:31,marginTop:'4%',fontWeight:'bold',fontFamily:'Archivo',lineHeight:1.4}}>Frequently Asked Questions</p>
<div style={{marginTop:70,marginLeft:'10%',marginRight:'10%'}}>
        <Accordion style={{display:'flex',flexDirection:'column',gap:24,backgroundColor:'transparent'}} >
  <Accordion.Panel   className="custom-accordion-panel" header="Accordion Panel 1" defaultExpanded style={{border:"0.5px solid grey",backgroundColor:'#F6F6F6',fontWeight:500,fontFamily:"Archivo",fontSize:16,borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p   style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}>This is the content for Accordion Panel 1. You can add any text here as needed.</p>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel"  header="Accordion Panel 2" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}>This is the content for Accordion Panel 2. Customize this text for different panels.</p>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="Accordion Panel 3" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}>This is the content for Accordion Panel 3. Add any content you want here.</p>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="Accordion Panel 2" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}>This is the content for Accordion Panel 2. Customize this text for different panels.</p>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="Accordion Panel 2" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}>This is the content for Accordion Panel 2. Customize this text for different panels.</p>
  </Accordion.Panel>
</Accordion>
</div>

    </div>
<div className='container-fluid ' style={{backgroundColor:"#dfc6ef",marginTop:'4%',display:'flex',justifyContent:'space-between',padding:'14%',paddingTop:'5%',paddingBottom:'5%',flexWrap:'wrap'}}>
    <div style={{display:'flex',flexDirection:'column',gap:16,backgroundColor:'transparent'}} >
    <div style={{backgroundColor:"#dfc6ef",fontFamily:'Archivo',fontWeight:'bold',fontSize:32}}>Grow your business<br/> today</div>
    <div style={{backgroundColor:"#dfc6ef",fontFamily:'Archivo',fontWeight:400,fontSize:15}}>Try it free. No credit card required. Instant setup.</div>
    </div>
    <div style={{display:'flex',flexDirection:'row',gap:8,backgroundColor:'transparent',alignSelf:'center'}}>
    <input className='main-input-filled' type="text" id="fname" name="fname" style={{backgroundColor:'transparent',width:358,height:40,paddingLeft:8,outline:"none"}}/>
    <div className='main-input-button' style={{backgroundColor:'#553285',fontFamily:'Archivo',fontWeight:600,fontSize:19,height:44,alignContent:'center',width:200,textAlign:'center',color:'white',borderRadius:4}}>Submit</div>

    </div>
</div>
</>
  )
}

export default Faq