import React, { useEffect, useState } from "react";
import "./BlogList.css"; // Importing the CSS for styling
import axios from "axios";
import { Link } from "react-router-dom";

function Blog() {
  const [blog, setBlog] = useState([]);
  const blogHandler = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    console.log(token);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/blog`,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.view_blog);
        setBlog(response.data.data.view_blog);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    blogHandler();
  }, []);

  const blogs = [
    {
      id: 1,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 1",
      description: "This is the subheading for blog post 1.",
    },
    {
      id: 2,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 2",
      description: "This is the subheading for blog post 2.",
    },
    {
      id: 3,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 3",
      description: "This is the subheading for blog post 3.",
    },
    {
      id: 4,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 4",
      description: "This is the subheading for blog post 4.",
    },
    {
      id: 5,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 5",
      description: "This is the subheading for blog post 5.",
    },
    {
      id: 6,
      image: "https://via.placeholder.com/150",
      title: "Blog Post 6",
      description: "This is the subheading for blog post 6.",
    },
  ];
  return (
    <div className="container">
      <div
        style={{
          gap: 16,
          display: "flex",
          flexDirection: "column",
          marginTop: "40px",
        }}
      >
        <div
          style={{
            fontSize: "40px",
            fontWeight: "600",
            color: "#1F1F1F",
            textAlign: "center",
          }}
        >
          Our Blogs
        </div>
        <p
          style={{
            textAlign: "center",
            color: "#8A8A8A",
            fontSize: 24,
            fontWeight: 500,
          }}
        >
          Expert Tips & Insights from Minemi
        </p>
      </div>

      <div style={{}} className="blog-list-container">
        {blog?.map((blog) => (
          <div className="blog-container" key={blog.id}>
            <Link
              to="/blogDetail"
              state={{
                bslug: blog.bslug,
              }}
              // onClick={scrollToTop}
              // className="menu-link menu-link_us-s"
            >
              <img src={blog.image} alt={blog.heading} className="blog-image" />
              <h2 className="blog-heading">{blog.title}</h2>
              <div className="blog-subheading" dangerouslySetInnerHTML={{ __html: blog.description }} />

              {/* <p className="blog-subheading">{blog.description}</p> */}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;
