import React, { useState, useEffect } from "react";
import logo from "../../Assets/Images/Logo.png";
import mlogo from "../../Assets/Images/mLogo.png";
import task from "../../Assets/Images/task.png";
import rightArrow from "../../Assets/Images/rightArrow.png";
import leftArrow from "../../Assets/Images/leftArrow.png";
import LowerEmi from "../../Assets/Images/lowerEmi.png";
import { BsList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profilephoto from "../../Assets/Images/avtar.jpg";
import { BiHomeAlt } from "react-icons/bi";
import "./NavBar.css";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { HashLink as RouterHashLink } from "react-router-hash-link";
import loans from "../../Assets/Images/Loans.png";
import activeloan from "../../Assets/Images/ActiveLoan.png";
import ActiveTask from "../../Assets/Images/ActiveTask.png";
import consolidates from "../../Assets/Images/consolidates.png";
import Activeconsolidates from "../../Assets/Images/activeconsolidate.png";
import Inactivecredit from "../../Assets/Images/InactiveCredit.png";
import Credits from "../../Assets/Images/creditScore.png";
import InactiveTop from "../../Assets/Images/inactivetopup.png";
import activeTop from "../../Assets/Images/activeTopup.png";
import inactivelower from "../../Assets/Images/inactiverlower.png";
import activelower from "../../Assets/Images/activelower.png";
// import blog from "../../Assets/Images/blog.png";
import activeblog from "../../Assets/Images/activeBlog.png";
import inactiveblog from "../../Assets/Images/inactiveblog.png";
import deleteprofile from "../../Assets/Images/user.png";
import turnoff from "../../Assets/Images/turn-off.png";
const NavBar = () => {
  const [progress, setprogress] = useState(0);
  const [status, setStatus] = useState(false);
  const [home, setHome] = useState(true);
  const [loan, setLoan] = useState(false);
  const [statusD, setStatusD] = useState(false);
  const [blog, setBlog] = useState(false);
  const [homeD, setHomeD] = useState(true);
  const [loanD, setLoanD] = useState(false);
  const [Lower, setLower] = useState(false);
  const [Consolidates, setConsolidate] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [credit, setCredit] = useState(false);
  const token = localStorage.getItem("token");
  const [LoanDetail, setLoanDetail] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const profile = localStorage.getItem("profile");
  const name = localStorage.getItem("name");
  const FirstName = localStorage.getItem("FirstName");
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("sssssss", location.pathname);

  const viewProfileHandler = () => {
    setprogress(30);
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/detail`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        navigate("/updateprofile", {
          state: {
            name1: response.data.data.user.name,
            pan1: response.data.data.user.pan,
            dob1: response.data.data.user.dob,
            gender1: response.data.data.user.gender,
            avatar1: response.data.data.user.avatar,
          },
        });
        localStorage.setItem("name", response.data.data.user.name);
        localStorage.setItem("profile", response.data.data.user.avatar);
        setprogress(100);
      })
      .catch(function (error) {
        console.error(error);
        setprogress(100);
      });
  };
  const CreditSummary = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setLoanDetail(response.data.data.loanDetail);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    if (token != null) {
      const token = localStorage.getItem("token");

      CreditSummary();
    } else {
      const token = localStorage.getItem("token");

      // console.log("token", token);
    }
  }, []);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const LogoutHandler = () => {
    const token = localStorage.getItem("token");
    // console.log("token", token);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/logout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("FirstName");
        setIsOpen(!isOpen);
        navigate("/login");
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const id = localStorage.getItem("id");

  return (
    <div>
      <LoadingBar color=" #59266d" progress={progress} />
      <nav className="navBar">
        <div className="navLeft">
          <div className="logoContainer">
            <Link to="/" style={{ backgroundColor: "transparent" }}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="navRight">
          <div className="navLinks" style={{backgroundColor:'white'}}>
            <ul style={{ border: "1px soild red" }}>
              {token == null ? (
                <>
                  <Link to="/">
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onClick={() => {
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/">
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onClick={() => {
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              )}
              <RouterHashLink to="/#DownloadApp">
                <li
                  style={{
                    fontWeight: loanD ? "600" : "300",
                    fontSize: 16,
                    color: loanD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(true);
                    setStatusD(false);
                    setBlog(false);
                  }}
                >
                  Loans
                </li>
              </RouterHashLink>
              <RouterHashLink to="/#DownloadApp">
                <li
                  style={{
                    fontWeight: statusD ? "600" : "300",
                    fontSize: 16,
                    color: statusD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setStatusD(true);
                    setBlog(false);
                  }}
                >
                  Services
                </li>
              </RouterHashLink>
              <Link to="/blog">
                <li
                  style={{
                    fontWeight: blog ? "600" : "300",
                    fontSize: 16,
                    color: statusD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(true);
                  }}
                >
                  Blogs
                </li>
              </Link>
            </ul>
          </div>
          <div className="navStatus">
            {token == null ? (
              <RouterHashLink to="#DownloadApp">
                <button className="loginDesktop" style={{ cursor: "pointer" }}>
                  Log in
                  <img src={rightArrow} alt="" />
                </button>
              </RouterHashLink>
            ) : (
              <button
                className="loginDesktop"
                style={{ cursor: "pointer" }}
                onClick={() => LogoutHandler()}
              >
                Log out
                <img src={rightArrow} alt="" />
              </button>
            )}
          </div>
        </div>
      </nav>
      <nav className="mobileNavBar">
        <div className="mLogo">
          <Link to="/dashboard" style={{ backgroundColor: "transparent" }}>
            <img src={mlogo} alt="" />
          </Link>
        </div>
        {["/otp", "/goal"].includes(location.pathname) ? null : (
          <BsList
            onClick={toggleSidebar}
            size={24}
            color="#1F1F1F"
            style={{ backgroundColor: "#ffff", cursor: "pointer" }}
          />
        )}
      </nav>
      {isOpen && (
        <div
          className={`sidebar ${isOpen ? "open" : ""}`}
          style={{ overflowY: "scroll" }}
        >
          <div style={{ backgroundColor: "white" }}>
            <div style={{ backgroundColor: "white" }}>
              <div style={{ height: "72px", backgroundColor: "white" }}></div>
              <div className="menu">
                <RxCross2
                  onClick={toggleSidebar}
                  size={16}
                  color="#1F1F1F"
                  style={{ backgroundColor: "#ffff" }}
                />
                <div className="frame">
                  <div className="menuLogin">
                    <div
                      className="menuArrow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      {" "}
                      {token ? (
                        <img src={leftArrow} alt="" />
                      ) : (
                        <img src={leftArrow} alt="" />
                      )}
                    </div>
                    {token != null ? (
                      <Link
                        to="/Dashboard"
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Dashboard
                        </div>{" "}
                      </Link>
                    ) : (
                      <Link
                        to="/login"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Log in
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {token && (
                <div
                  onClick={() => {
                    viewProfileHandler();
                    setIsOpen(!isOpen);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "transparent",
                      backgroundColor: "#846CA6",
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      boxShadow: "1.3px 1.3px 8px #888888",
                    }}
                  >
                    <img
                      src={profilephoto}
                      width="64"
                      height="64"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto 0",
                      marginLeft: "10px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "13.06px",
                        textAlign: "left",
                        paddingBottom: "9px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {" "}
                      👋 Hy!
                    </div>

                    {FirstName == "null" ? null : (
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          lineHeight: "17.41px",
                          textAlign: "left",
                          backgroundColor: "transparent",
                        }}
                      >
                        {FirstName}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="menuLinks" style={{ backgroundColor: "white" }}>
                <ul style={{ backgroundColor: "white" }}>
                  {token === null ? (
                    <Link to="/" style={{ backgroundColor: "white" }}>
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          backgroundColor: "white",
                          cursor: "pointer",
                          opacity: home ? "" : "40%",
                          color: home ? "#59266D" : "rgba(31, 31, 31, 1)",
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false); 
                        }}
                      >
                        <BiHomeAlt
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                            opacity: home ? "" : "40%",
                            color: home ? "rgb(89, 38, 109)" : "",
                          }}
                        />
                        Home
                      </li>
                    </Link>
                  ) : (
                    <Link to="/dashboard" style={{ backgroundColor: "white" }}>
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                        }}
                      >
                        <BiHomeAlt
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                            opacity: home ? "" : "40%",
                            color: home ? "rgb(89, 38, 109)" : "",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight: home ? "700" : "500",
                            opacity: home ? "" : "40%",
                            color: home ? "#59266D" : "rgba(31, 31, 31, 1)",
                          }}
                        >
                          Home
                        </span>
                      </li>
                    </Link>
                  )}
                  {token == null ? //     Loans //     /> //       style={{ backgroundColor: "white" }} //       weight="20.9" //       height="20.9" //       alt="" //       src={loan ? activeloan : loans} //     <img //   > //     }} //       color: loan ? "#59266D" : "rgba(31, 31, 31, 1)", //       opacity: loan ? "" : "40%", //       fontWeight: loan ? "700" : "500", //       backgroundColor: "white", //       fontSize: 14, //       fontWeight: 500, //     style={{ //     }} //       setCredit(false); //       setTopup(false); //       setConsolidate(false); //       setLower(false); //       setIsOpen(!isOpen); //       setStatus(false); //       setHome(false); //       setLoan(true); //     onClick={() => { //   <li // <Link to="/login" style={{ backgroundColor: "white" }}>
                  //   </li>
                  // </Link>
                  null : (
                    <Link
                      to="/loanoverview"
                      style={{ backgroundColor: "white" }}
                    >
                      <li
                        onClick={() => {
                          setLoan(true);
                          setHome(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                        }}
                        style={{
                          fontWeight: 500,
                          fontSize: 14,
                          backgroundColor: "white",
                          fontWeight: loan ? "700" : "500",
                          opacity: loan ? "" : "40%",
                          color: loan ? "#59266D" : "rgba(31, 31, 31, 1)",
                        }}
                      >
                        <img
                          src={loan ? activeloan : loans}
                          alt=""
                          height="20.9"
                          weight="20.9"
                          style={{ backgroundColor: "white" }}
                        />
                        Loans
                      </li>
                    </Link>
                  )}
                  {token == null ? //     Status //     /> //       style={{ backgroundColor: "white" }} //       alt="" //       src={status ? ActiveTask : task} //     <img //   > //     }} //       fontSize: 14, //       backgroundColor: "white", //       color: status ? "#59266D" : "rgba(31, 31, 31, 1)", //       opacity: status ? "" : "40%", //       fontWeight: status ? "700" : "500", //     style={{ //     }} //       setCredit(false); //       setTopup(false); //       setConsolidate(false); //       setLower(false); //       setIsOpen(!isOpen); //       setHome(false); //       setLoan(false); //       setStatus(true); //     onClick={() => { //   <li // > //   style={{ backgroundColor: "transparent" }} //   to="/login" // <Link
                  //   </li>
                  // </Link>
                  null : (
                    <Link
                      to="/applicationstatus"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setStatus(true);
                          setLoan(false);
                          setHome(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                        }}
                        style={{
                          fontWeight: status ? "700" : "500",
                          opacity: status ? "" : "40%",
                          color: status ? "#59266D" : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                          fontSize: 14,
                        }}
                      >
                        <img
                          src={status ? ActiveTask : task}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Status
                      </li>
                    </Link>
                  )}
                  <Link to="/blog" style={{ backgroundColor: "transparent" }}>
                    <li
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setLower(false);
                        setConsolidate(false);
                        setTopup(false);
                        setHome(false);
                        setStatus(false);
                        setLoan(false);
                        setCredit(false);
                        setBlog(true);
                      }}
                      style={{
                        fontWeight: blog ? "700" : "500",
                        fontSize: 14,
                        color: blog
                          ? "rgb(89, 38, 109)"
                          : "rgba(31, 31, 31, 1)",
                        opacity: blog ? " " : "40%",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={blog ? inactiveblog : activeblog}
                        alt=""
                        style={{ backgroundColor: "white" }}
                      />
                      Blogs
                    </li>
                  </Link>

                  {token == null ? (
                    <Link
                      to="/login"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                        }}
                        style={{
                          fontWeight: Lower ? "700" : "500",
                          fontSize: 14,
                          color: Lower
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: Lower ? " " : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Lower ? activelower : inactivelower}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Lower my EMIsss
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 1);
                          setIsOpen(!isOpen);
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Lower ? "700" : "500",
                          color: Lower
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: Lower ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Lower ? activelower : LowerEmi}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Lower my EMI
                      </li>
                    </Link>
                  )}

                  {token == null ? (
                    <Link
                      to="/login"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Consolidates ? "700" : "500",
                          color: Consolidates
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: Consolidates ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Consolidates ? Activeconsolidates : consolidates}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Consolidate High Interest Debt
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 2);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Consolidates ? "700" : "500",
                          color: Consolidates
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: Consolidates ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Consolidates ? Activeconsolidates : consolidates}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Consolidate High Interest Debt
                      </li>
                    </Link>
                  )}
                  {token == null ? (
                    <Link
                      to="/login"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: topUp ? "700" : "500",
                          color: topUp
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: topUp ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={topUp ? activeTop : InactiveTop}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Transfer Loan With a Top-Up
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 3);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: topUp ? "700" : "500",
                          color: topUp
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: topUp ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={topUp ? activeTop : InactiveTop}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Transfer Loan With a Top-Up
                      </li>
                    </Link>
                  )}

                  {token == null ? (
                    <Link
                      to="/login"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(true);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: credit ? "700" : "500",
                          color: credit
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: credit ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={credit ? Credits : Inactivecredit}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Check Credit Score
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/dashboard"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(true);
                          setBlog(false);

                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: credit ? "700" : "500",
                          color: credit
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          opacity: credit ? "" : "40%",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={credit ? Credits : Inactivecredit}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Check Credit Score
                      </li>
                    </Link>
                  )}
                  {token === null ? null : (
                    <li
                      onClick={() => {
                        // localStorage.removeItem("token");
                        LogoutHandler();
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "rgba(31, 31, 31, 1)",
                        opacity: "40%",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={turnoff}
                        alt=""
                        style={{ backgroundColor: "transparent" }}
                      />
                      Logout
                    </li>
                  )}

                  {token === null ? null : (
                    <li
                      onClick={() => {
                        toggleSidebar();
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "700",
                        color: "red",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={deleteprofile}
                        alt=""
                        style={{ backgroundColor: "transparent" }}
                      />
                      Delete Account
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
