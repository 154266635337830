import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Link, useNavigate } from "react-router-dom";
import "./statuss.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import nostatus from "../../Assets/Images/nostatuss.png";

function Statuss() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const ListHandler = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/applicationForm/list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data.status === 1) {
          setData(response.data.data.list);
          // console.log(response.data.data);
        } else {
          toast(response.data.msg);
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    ListHandler();
  }, []);
  let percentage = 0;
  if (data.status === "Application Submitted") {
    percentage = 0;
  } else if (data.status === "Document Verified") {
    percentage = 35;
  } else if (data.status === "In Progress") {
    percentage = 70;
  } else if (data.status === "MinEMI Approved") {
    percentage = 100;
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
      // console.log("user Login");
    }
  }, []);
  return (
    <>
      {/* <ToastContainer style={{ backgroundColor: "white" }} /> */}
      {data.length == 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            marginTop: "15%",
            marginBottom: "15%",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <div style={{ height: 86, width: 90 }}>
            <img src={nostatus} style={{ width: "100%", height: "100%" }} />
          </div>
          <div style={{ fontSize: 14, fontWeight: "700" }}>
            No Application Submitted{" "}
          </div>
        </div>
      ) : (
        data?.map((d, index) => (
          <div
            key={index}
            className="status-box"
            style={{
              width: "408px",
              height: "500px",
              backgroundColor: "white",
              margin: "90px auto",
              borderRadius: "10px",
            }}
          >
            <div style={{ backgroundColor: "white" }}>
              <p
                style={{
                  backgroundColor: "white",
                  paddingTop: "50px",
                  paddingLeft: "20px",
                  color: "rgb(89, 38, 109)",
                }}
              >
                Application Submitted
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                backgroundColor: "transparent",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "20px",
                  paddingLeft: "40px",
                  fontWeight: "600px",
                }}
              >
                other
              </div>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginRight: "30px",
                }}
              >
                <img
                  src={d.bankLogo}
                  alt=""
                  width="40"
                  height="20"
                  style={{ backgroundColor: "transparent", marginTop: "5px" }}
                />
              </div>
            </div>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: "40px",
                marginLeft: "40px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  color: "rgb(89, 38, 109)",
                  fontWeight: "bolder",
                }}
              >
                Application Status
              </div>
            </div>
            <div
              className="progress-bar"
              style={{
                width: "300px",
                margin: "0 auto",
                paddingTop: "40px",
                backgroundColor: "transparent",
              }}
            >
              <ProgressBar
                percent={percentage}
                filledBackground="lightgreen"
                height={5}
              >
                <Step transition="scale">
                  {({ accomplished }) => (
                    <>
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "50px",
                          backgroundColor: accomplished
                            ? "lightgreen"
                            : d.status === "Application Submitted"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      ></div>
                      <span
                        style={{
                          position: "absolute",
                          top: "25px",
                          width: "90px",
                          left: "3px",
                          backgroundColor: "transparent",
                          fontSize: "10px",
                          color: accomplished
                            ? "lightgreen"
                            : d.status === "Application Submitted"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      >
                        Application Submitted
                      </span>
                    </>
                  )}
                </Step>
                <Step transition="scale">
                  {({ accomplished }) => (
                    <>
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "50px",
                          backgroundColor: accomplished
                            ? "lightgreen"
                            : d.status === "Document Verified"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      ></div>
                      <span
                        style={{
                          position: "absolute",
                          top: "25px",
                          width: "90px",
                          left: "3px",
                          backgroundColor: "transparent",
                          fontSize: "10px",
                          color: accomplished
                            ? "lightgreen"
                            : d.status === "Document Verified"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      >
                        Document <br /> Verified
                      </span>
                    </>
                  )}
                </Step>

                <Step transition="scale">
                  {({ accomplished }) => (
                    <>
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "50px",
                          backgroundColor: accomplished
                            ? "lightgreen"
                            : d.status === "In Progess"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      ></div>
                      <span
                        style={{
                          position: "absolute",
                          top: "25px",
                          width: "90px",
                          left: "3px",
                          backgroundColor: "transparent",
                          fontSize: "10px",
                          color: accomplished
                            ? "lightgreen"
                            : d.status === "In Progress"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      >
                        In Progress
                      </span>
                    </>
                  )}
                </Step>
                <Step transition="scale">
                  {({ accomplished }) => (
                    <>
                      <div
                        style={{
                          width: "18px",
                          height: "18px",
                          borderRadius: "50px",
                          backgroundColor: accomplished
                            ? "lightgreen"
                            : d.status === "MinEMI Approved"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      ></div>
                      <span
                        style={{
                          position: "absolute",
                          top: "25px",
                          width: "39px",
                          backgroundColor: "transparent",
                          fontSize: "10px",
                          color: accomplished
                            ? "lightgreen"
                            : d.status === "MinEMI Approved"
                            ? "lightgreen"
                            : "#d3d3d3",
                        }}
                      >
                        MinEMI Approved
                      </span>
                    </>
                  )}
                </Step>
              </ProgressBar>
            </div>
            <div
              style={{
                display: "flex",
                gap: "90px",
                marginTop: "70px",
                marginLeft: "40px",
                backgroundColor: "transparent",
              }}
            >
              <div style={{ backgroundColor: "transparent", fontSize: "14px" }}>
                Loan Amount
              </div>
              <div style={{ backgroundColor: "transparent", fontSize: "13px" }}>
                ₹{d.loanamount}
              </div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                gap: "130px",
                marginTop: "13px",
                marginLeft: "40px",
                backgroundColor: "transparent",
              }}
            >
              <div style={{ backgroundColor: "transparent", fontSize: "14px" }}>
                Tenure
              </div>
              <div style={{ backgroundColor: "transparent", fontSize: "13px" }}>
                {d.remaining_tenure}Months
              </div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                gap: "95px",
                marginTop: "13px",
                marginLeft: "40px",
                backgroundColor: "transparent",
              }}
            >
              <div style={{ backgroundColor: "transparent", fontSize: "14px" }}>
                Interest Rate
              </div>
              <div style={{ backgroundColor: "transparent", fontSize: "13px" }}>
                {d.interestRate}%
              </div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                gap: "97px",
                marginTop: "13px",
                marginLeft: "40px",
                backgroundColor: "transparent",
              }}
            >
              <div style={{ backgroundColor: "transparent", fontSize: "14px" }}>
                Monthly EMI
              </div>
              <div style={{ backgroundColor: "transparent", fontSize: "13px" }}>
                ₹{d.currentemi}
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                color: "rgb(89, 38, 109)",
                backgroundColor: "transparent",
                fontWeight: "700",
                fontSize: "14px",
                marginTop: "30px",
              }}
            >
              You are saving ₹{d.savingAmount} with this offer
            </div>
            <Link
              state={{
                docId: d.id,
              }}
              to="/statusdetail"
            >
              {" "}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  backgroundColor: "transparent",
                }}
              >
                <button
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgb(89, 38, 109)",
                    color: "white",
                    margin: "0 auto",
                    border: "none",
                    outline: "none",
                    paddingLeft: "90px",
                    paddingRight: "90px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "10px",
                  }}
                >
                  View Application
                </button>
              </div>
            </Link>
          </div>
        ))
      )}
    </>
  );
}

export default Statuss;
