import React, { useState, useEffect } from "react";
import HomeLogo from "../../Assets/Images/HomeLogo.png";
import userLogo from "../../Assets/Images/userLogo.png";
import Credit from "../../Assets/Images/Credit.png";
import "./Loan.css";
import ApexChart from "./ApexChart";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function Loan() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [emi, setEmi] = useState([]);
  const [data1, setData] = useState([]);
  const location = useLocation();
  const { state } = location;
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [series1, setSeries1] = useState("");
  const [series2, setSeries2] = useState("");
  const [series3, setSeries3] = useState("");
  const [series4, setSeries4] = useState("");
  const HandleClick1 = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/loanOverview`,
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setName1(response.data.data.loanChart[0].loan_Category);
        setName2(response.data.data.loanChart[1].loan_Category);
        setName3(response.data.data.loanChart[2].loan_Category);
        setName4(response.data.data.loanChart[3].loan_Category);
        setSeries1(response.data.data.loanChart[0].LoanPercentage);
        setSeries2(response.data.data.loanChart[1].LoanPercentage);
        setSeries3(response.data.data.loanChart[2].LoanPercentage);
        setSeries4(response.data.data.loanChart[3].LoanPercentage);
        setAmount(response.data.data.loanOutstanding);
        setData(response.data.data.loanDetail);
        setEmi(response.data.data.loanChart);
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    HandleClick1();
  }, []);
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
      // console.log("user Login")
    }
  }, []);
  return (
    <div style={{ marginTop: "60px" }}>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="box-1-loan"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "1240px",
            borderRadius: "10px",
            borderBottomLeftRadius: "10px",
            backgroundColor: "#59266D",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#59266D",
              paddingTop: "40px",
              gap: "16px",
              width: "358px",
              paddingLeft: "40px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#F2F2F2",
                backgroundColor: "transparent",
              }}
            >
              ₹ {formatNumberWithCommas(amount)}
            </div>

            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#F2F2F2",
                backgroundColor: "transparent",
                paddingBottom: "20px",
              }}
            >
              Outstanding Amount
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "22px",
                letterSpacing: "0em",
                textAlign: "left",
                backgroundColor: "transparent",
                color: "#F2F2F2",
                marginBottom: "40px",
              }}
            >
              Monthly Payment :<br />
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "800",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  backgroundColor: "transparent",
                  color: "#F2F2F2",
                }}
              >
                ₹45,000
              </span>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "rgb(89, 38, 109)",
              marginRight: "40px",
              alignSelf: "center",
              display: "flex",
              marginBottom: "40px",
              justifyContent: "center",
            }}
          >
            <ApexChart style={{ backgroundColor: "rgb(89, 38, 109)" }} />
            <div
              style={{
                backgroundColor: "transparent",
              }}
            >
              <div style={{ display: "flex", backgroundColor: "transparent" }}>
                <div
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#FFF500",
                    borderRadius: "10px",
                    marginRight: "5px",
                  }}
                ></div>

                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#FFF500",
                  }}
                >
                  {series1}%
                </span>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#FFF500",
                    width: "90px",
                  }}
                >
                  &nbsp;{name1}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#70FF00",
                    borderRadius: "10px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                </div>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#70FF00",
                  }}
                >
                  {" "}
                  {series2}%
                </span>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#70FF00",
                    width: "90px",
                  }}
                >
                  &nbsp;{name2}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#FF6B00",
                    borderRadius: "10px",
                    marginRight: "5px",
                  }}
                ></div>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#FF6B00",
                  }}
                >
                  {" "}
                  {series3}%
                </span>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#FF6B00",
                    width: "130px",
                  }}
                >
                  &nbsp;{name3}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "transparent",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#00D1FF",
                    borderRadius: "10px",
                    marginRight: "5px",
                  }}
                ></div>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#00D1FF",
                  }}
                >
                  {" "}
                  {series4}%
                </span>
                <span
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "10px",
                    alignSelf: "center",
                    color: "#00D1FF",
                    width: "90px",
                  }}
                >
                  &nbsp;{name4}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="remove-ml"
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginLeft: "140px",
          gap: "16px",
          marginTop: "40px",
          marginBottom: "50px",
        }}
      >
        {data1.map((d, index) => (
          <div
            key={index}
            className="loan-box-size"
            style={{
              width: "390px",
              height: "88px",
              borderRadius: "10px",
              background: "#FFFFFF",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "5%",
            }}
          >
            <div style={{ backgroundColor: "transparent", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingBottom: "10px",
                  backgroundColor: "transparent",
                  paddingTop: "20px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    backgroundColor: "transparent",
                    // width:200
                  }}
                >
                  <div
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "rgba(85, 50, 133, 0.1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {d.Account_Type == "05" ? (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100px",
                        }}
                      >
                        <img
                          src={userLogo}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "100px",
                            mixBlendMode: "multiply",
                            resizeMode: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100px",
                        }}
                      >
                        <img
                          src={Credit}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            // backgroundColor: "transparent",s
                            // position: "relative",
                            // top: "7px",
                            borderRadius: "100px",
                            mixBlendMode: "multiply",
                            resizeMode: "cover",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    key={index}
                    style={{
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {d.loanType === "PL" ? (
                      <>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "15px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(31, 31, 31, 1)",
                            paddingTop: "10px",
                            backgroundColor: "transparent",
                          }}
                        >
                          Personal Loan
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineheight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(138, 138, 138, 1)",
                            paddingTop: "5px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {d.Subscriber_Name.toUpperCase()}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "15px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(31, 31, 31, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          {d.loanName.slice(0, 13)}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineheight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(138, 138, 138, 1)",
                            paddingTop: "5px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {d.Subscriber_Name.toUpperCase()}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {d.Current_Balance == 0 ? null : (
                  <div
                    style={{
                      backgroundColor: "transparent",
                      alignSelf: "center",
                    }}
                  >
                    <div
                      style={{
                        // paddingTop: "8px",
                        // paddingRight: "11px",
                        // paddingBottom: "8px",
                        // paddingLeft: "11px",
                        width: 110,
                        height: 40,
                        borderRadius: "100px",
                        // gap: "8px",
                        backgroundColor: "#5532851A",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "13px",
                        letterSpacing: "0em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Outstanding <br />₹{" "}
                      {formatNumberWithCommas(d.Current_Balance)}
                    </div>
                    {emi.map((d, index) => (
                      <>
                        {d.LoanEmi == 0 ? null : (
                          <div
                            key={index}
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              lineHeight: "11px",
                              letterSpacing: "0em",
                              textAlign: "center",
                              color: "#1F1F1F",
                              backgroundColor: "transparent",
                              paddingTop: "5px",
                            }}
                          >
                            Current EMI 10,000 p/m
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loan;
