import React, { useState, useEffect } from "react";
import HomeLogo from "../../Assets/Images/HomeLogo.png";
import userLogo from "../../Assets/Images/userLogo.png";
import Illustration from "../../Assets/Images/Illustration.png";
import "./LowerEmiDetailPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import check from "../../Assets/Images/check.png";
import uncheck from "../../Assets/Images/uncheck.png";

function LowerEmiDetailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [All, setAll] = useState(true);
  const [homeloan, setHomeLoan] = useState(false);
  const [personalloan, setPersonalLoan] = useState(false);
  const [data1, setData] = useState(state.Loan.loanList);
  const [loanAmount, setloanAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [oldirr, setoldIrr] = useState("");
  const [newirr, setNewIrr] = useState("");
  const [accontType, setAccountType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const id = localStorage.getItem("id");
  const [val, setVal] = useState(null);
  // console.log(data1);
  // console.log(val);

  const Allsection = () => {
    setAll(true);
    setHomeLoan(false);
    setPersonalLoan(false);
  };
  const Homeloan = () => {
    setHomeLoan(true);
    setAll(false);
    setPersonalLoan(false);
  };
  const personalLoan = () => {
    setHomeLoan(false);
    setAll(false);
    setPersonalLoan(true);
  };
  // console.log(state);
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  useEffect(()=>{
    const token = localStorage.getItem("token")
    if(token==null){
      navigate("/")
    } else{
      // console.log("user Login")
    }
  },[])
  return (
    <div
      className="main-LowerEmi-Box"
      style={{ marginLeft: "150px", marginTop: "60px" }}
    >
      <div
        className="mobileScreen1"
        style={{
          textAlign: "center",
          display: id === "1" || id === 2 ? "" : "none",
        }}
      >
        <img
          src={Illustration}
          alt=""
          style={{
            width: "266px",
            height: "252px",
          }}
        />
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "32px",
            letterSpacing: "0em",
            textAlign: "center",
          }}
        >
          You can save
          <br />
          <span
            style={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "32px",
              letterSpacing: "0em",
              color: "#59266D",
            }}
          >
            Rs {formatNumberWithCommas(state.Loan.emiSaved)}
          </span>
          <div
            className="mobile-version"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "25px",
              letterSpacing: "0em",

              color: "#8A8A8A",
            }}
          >
            Please select the loans to lower your EMI
          </div>
        </div>
      </div>
      <div
        className="Nav-Point"
        style={{ display: "flex", gap: "27px", marginBottom: "30px" }}
      >
        <div
          style={
            All
              ? {
                  fontSize: "16px",
                  textDecoration: "underline",
                  textUnderlineOffset: "0.5rem",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "black",
                }
              : {
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#8A8A8A",
                  cursor: "pointer",
                }
          }
          onClick={Allsection}
        >
          All
        </div>
        <div
          style={
            homeloan
              ? {
                  fontSize: "16px",
                  textDecoration: "underline",
                  textUnderlineOffset: "0.5rem",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "black",
                }
              : {
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#8A8A8A",
                  cursor: "pointer",
                }
          }
          onClick={Homeloan}
        >
          Home Loan
        </div>
        <div
          style={
            personalloan
              ? {
                  fontSize: "16px",
                  textDecoration: "underline",
                  textUnderlineOffset: "0.5rem",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "black",
                }
              : {
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#8A8A8A",
                  cursor: "pointer",
                }
          }
          onClick={personalLoan}
        >
          Personal Loan
        </div>
      </div>

      <div
        className="remove-margin"
        style={{
          display: "flex",
          justifyContent: "center",
          marginRight: "60px",
        }}
      >
        <div>
          {All && (
            <div
              className="main-flex-box"
              style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}
            >
              <>
                {data1.map((d, index) => (
                  <>
                    {d.status == "1" && (
                      <div
                        className="content-box-1"
                        key={index}
                        style={{
                          width: "358px",
                          height: "112px",
                          borderRadius: "8px",
                          backgroundColor: "#FFFFFF",
                          borderLeft: "1px solid #009964",
                          borderLeftWidth: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "transparent",
                            paddingLeft: "10px",
                            paddingTop: "20px",
                            paddingBottom: "10px",
                            borderBottom: " 0.5px solid rgba(89, 38, 109, 1) ",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              backgroundColor: "transparent",
                            }}
                          >
                            {id == "1" || id == "2" ? (
                              <div
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "100px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                  alignSelf: "center",
                                }}
                              >
                                <img
                                  src={val === index ? check : uncheck}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() => {
                                    setloanAmount(d.loanAmount);
                                    setNewIrr(d.new_Irr);
                                    setoldIrr(d.oldIrr);
                                    setTenure(d.new_tenure);
                                    setAccountNumber(d.Account_Number);
                                    setAccountType(d.account_type);
                                    setVal(val == index ? null : index);
                                  }}
                                />
                              </div>
                            ) : null}

                            <div
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: 100,
                                backgroundColor: "rgba(85, 50, 133, 0.1)",
                                textAlign: "center",
                                alignSelf: "center",
                              }}
                            >
                              {d.account_type == "05" ? (
                                <img
                                  src={userLogo}
                                  alt=""
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: 100,
                                    backgroundColor: "transparent",
                                  }}
                                />
                              ) : (
                                <img
                                  src={HomeLogo}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 100,
                                    backgroundColor: "transparent",
                                  }}
                                />
                              )}
                            </div>
                            <div style={{ backgroundColor: "transparent" }}>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "15px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                  color: "rgba(31, 31, 31, 1)",
                                  paddingTop: "10px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {d.loan_name}
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineheight: "13px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                  color: "rgba(138, 138, 138, 1)",
                                  paddingTop: "5px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {d.subscriber_name
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")
                                  .toUpperCase()}
                              </div>
                            </div>
                          </div>
                          {d.save_on_emi == 0 || isNaN(d.save_on_emi) ? null : (
                            <div
                              className="fontSize-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "13px",
                                letterSpacing: "0em",
                                color: " #009964",
                                // paddingTop: "18px",
                                display: id == 3 ? "none" : "",
                                // paddingLeft: "8px",
                                // paddingRight: "8px",
                                width: 140,
                                height: 40,

                                borderRadius: "100px",
                                backgroundColor: " #0099641A",
                                marginRight: "2px",
                                marginLeft: "4px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                alignSelf: "center",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(0, 153, 100)",
                                border: "2px soloid black",
                              }}
                            >
                              Saving ₹ {formatNumberWithCommas(d.save_on_emi)}
                            </div>
                          )}
                        </div>
                        {id == "1" || id == "2" ? (
                          <Link
                            to="/details"
                            state={{
                              oldIrr: d.oldIrr,
                              newIrr: d.new_Irr,
                              loanAmount: d.loanAmount,
                              tenure: d.new_tenure,
                              oldemi: d.old_emi,
                              newemi: d.new_emi,
                              saveonemi: d.save_on_emi,
                              accountType: d.account_type,
                              subscriber: d.subscriber_name,
                              savepercentage: d.save_percentage,
                              Account_Number: d.Account_Number,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "13px",
                                letterSpacing: "0em",
                                backgroundColor: "transparent",
                                paddingTop: "10px",
                                color: "#846CA6",
                              }}
                            >
                              Check Details
                            </div>
                          </Link>
                        ) : (
                          <Link
                            onClick={() => console.log(d.account_type)}
                            to="/topupdetail"
                            state={{
                              maxtopupallowed: d.max_topup_allowed,
                              loan_tenure: d.loan_tenure,
                              accountType: d.account_type,
                              CurrentBalance: d.Current_Balance,
                              Account_Number: d.Account_Number,
                              subscriber: d.subscriber_name,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "13px",
                                letterSpacing: "0em",
                                backgroundColor: "transparent",
                                paddingTop: "10px",
                                color: "#846CA6",
                              }}
                            >
                              Check Details
                            </div>
                          </Link>
                        )}
                      </div>
                    )}

                    {d.status == "0" && (
                      <div
                        className="content-box-1"
                        style={{
                          width: "358px",
                          height: "112px",
                          borderRadius: "8px",
                          backgroundColor: "#FFFFFF",
                          borderLeft: "1px solid #8A8A8A",
                          borderLeftWidth: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "transparent",
                            paddingTop: "20px",
                            paddingBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "12px",
                              backgroundColor: "transparent",
                            }}
                          >
                            <div
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: 100,
                                backgroundColor: "rgba(85, 50, 133, 0.1)",
                                textAlign: "center",
                                marginLeft: "20px",
                                alignSelf: "center",
                              }}
                            >
                              {d.account_type == "05" ? (
                                <img
                                  src={userLogo}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                />
                              ) : (
                                <img
                                  src={HomeLogo}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                  }}
                                />
                              )}
                            </div>
                            <div style={{ backgroundColor: "transparent" }}>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "15px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                  color: "rgba(31, 31, 31, 1)",
                                  paddingTop: "10px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {d.loan_name}
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineheight: "13px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                  color: "rgba(138, 138, 138, 1)",
                                  paddingTop: "5px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {d.subscriber_name
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")
                                  .toUpperCase()}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "13px",
                              letterSpacing: "0em",
                              textAlign: "center",
                              color: "#8A8A8A",
                              alignSelf: "center",
                              paddingLeft: "2%",
                              paddingRight: "3%",
                            }}
                          >
                            No saving opportunities
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            </div>
          )}

          {homeloan && (
            <div
              className="main-flex-box"
              style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}
            >
              <>
                {data1.map((d, index) => (
                  <>
                    {d.account_type == "02" && (
                      <>
                        {d.status == "1" ? (
                          <div
                            className="content-box-1"
                            style={{
                              width: "358px",
                              height: "112px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              borderLeft: "1px solid #009964",
                              borderLeftWidth: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "transparent",
                                paddingLeft: "10px",
                                paddingTop: "20px",
                                paddingBottom: "10px",
                                borderBottom:
                                  "0.5px solid rgba(89, 38, 109, 1)",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "12px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {id == "1" || id == "2" ? (
                                  <div
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "100px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginTop: "10px",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      src={val === index ? check : uncheck}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        setloanAmount(d.loanAmount);
                                        setNewIrr(d.new_Irr);
                                        setoldIrr(d.oldIrr);
                                        setTenure(d.new_tenure);
                                        setAccountNumber(d.Account_Number);
                                        setAccountType(d.account_type);
                                        setVal(index);
                                      }}
                                    />
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: 100,
                                    backgroundColor: "rgba(85, 50, 133, 0.1)",
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  {d.account_type == "05" ? (
                                    <img
                                      src={userLogo}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={HomeLogo}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                      }}
                                    />
                                  )}
                                </div>
                                <div style={{ backgroundColor: "transparent" }}>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      lineHeight: "15px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(31, 31, 31, 1)",
                                      paddingTop: "10px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.loan_name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      lineheight: "13px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(138, 138, 138, 1)",
                                      paddingTop: "5px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.subscriber_name
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")
                                      .toUpperCase()}
                                  </div>
                                </div>
                              </div>
                              {d.save_on_emi == 0 ||
                              isNaN(d.save_on_emi) ? null : (
                                <div
                                  className="fontSize-1"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    color: " #009964",
                                    // paddingTop: "18px",
                                    // paddingLeft: "10px",
                                    // paddingBottom: "14px",
                                    // paddingRight: "10px",
                                    width: 140,
                                    height: 40,
                                    borderRadius: 100,
                                    backgroundColor: " #0099641A",
                                    marginRight: "2px",
                                    marginLeft: "4px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                    display: id == 3 ? "none" : "",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "2px solid red",
                                  }}
                                >
                                  Saving ₹
                                  {formatNumberWithCommas(d.save_on_emi)}
                                </div>
                              )}
                            </div>
                            {id == "1" || id == "2" ? (
                              <Link
                                to="/details"
                                state={{
                                  oldIrr: d.oldIrr,
                                  newIrr: d.new_Irr,
                                  loanAmount: d.loanAmount,
                                  tenure: d.new_tenure,
                                  oldemi: d.old_emi,
                                  newemi: d.new_emi,
                                  saveonemi: d.save_on_emi,
                                  account_type: d.account_type,
                                  subscriber: d.subscriber_name,
                                  savepercentage: d.save_percentage,
                                  Account_Number: d.Account_Number,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    backgroundColor: "transparent",
                                    paddingTop: "10px",
                                    color: "#846CA6",
                                  }}
                                >
                                  Check Details
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to="/topupdetail"
                                state={{
                                  maxtopupallowed: d.max_topup_allowed,
                                  loan_tenure: d.loan_tenure,
                                  accountType: d.Account_Type,
                                  CurrentBalance: d.Current_Balance,
                                  Account_Number: d.Account_Number,
                                  subscriber: d.subscriber_name,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    backgroundColor: "transparent",
                                    paddingTop: "10px",
                                    color: "#846CA6",
                                  }}
                                >
                                  Check Details
                                </div>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <div
                            className="content-box-1"
                            style={{
                              width: "358px",
                              height: "112px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              borderLeft: "1px solid #8A8A8A",
                              borderLeftWidth: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "transparent",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                                paddingTop: "20px",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "12px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <div
                                  style={{
                                    width: "48px",
                                    height: "48px",
                                    borderRadius: "27px",
                                    backgroundColor: "rgba(85, 50, 133, 0.1)",
                                    textAlign: "center",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {d.account_type == "05" ? (
                                    <img
                                      src={userLogo}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        top: "7px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={HomeLogo}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        top: "7px",
                                      }}
                                    />
                                  )}
                                </div>
                                <div style={{ backgroundColor: "transparent" }}>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      lineHeight: "15px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(31, 31, 31, 1)",
                                      paddingTop: "10px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.loan_name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      lineheight: "13px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(138, 138, 138, 1)",
                                      paddingTop: "5px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.subscriber_name
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")
                                      .toUpperCase()}{" "}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "transparent",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "13px",
                                  letterSpacing: "0em",
                                  // textAlign: "left",
                                  color: "#8A8A8A",
                                  alignSelf: "center",
                                }}
                              >
                                No saving opportunities{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            </div>
          )}

          {personalloan && (
            <div
              className="main-flex-box"
              style={{ display: "flex", gap: "24px", flexWrap: "wrap" }}
            >
              <>
                {data1.map((d, index) => (
                  <>
                    {d.account_type == "05" && (
                      <>
                        {d.status == "1" ? (
                          <div
                            className="content-box-1"
                            style={{
                              width: "358px",
                              height: "112px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              borderLeft: "1px solid #009964",
                              borderLeftWidth: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "transparent",
                                paddingLeft: "10px",
                                paddingTop: "20px",
                                paddingBottom: "10px",
                                borderBottom:
                                  "0.5px solid rgba(89, 38, 109, 1)",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "12px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {id == "1" || id == "2" ? (
                                  <div
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      borderRadius: "100px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // marginTop: "10px",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      src={val === index ? check : uncheck}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => {
                                        setloanAmount(d.loanAmount);
                                        setNewIrr(d.new_Irr);
                                        setoldIrr(d.oldIrr);
                                        setTenure(d.new_tenure);
                                        setAccountNumber(d.Account_Number);
                                        setAccountType(d.account_type);
                                        setVal(index);
                                      }}
                                    />
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: 100,
                                    backgroundColor: "rgba(85, 50, 133, 0.1)",
                                    textAlign: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  {d.account_type == "05" ? (
                                    <img
                                      src={userLogo}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                        // position: "relative",
                                        // top: "7px",
                                        // border:'2px solid black'
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={HomeLogo}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        top: "7px",
                                        border: "2px solid black",
                                      }}
                                    />
                                  )}
                                </div>
                                <div style={{ backgroundColor: "transparent" }}>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      lineHeight: "15px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(31, 31, 31, 1)",
                                      paddingTop: "10px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.loan_name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      lineheight: "13px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(138, 138, 138, 1)",
                                      paddingTop: "5px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.subscriber_name
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")}
                                  </div>
                                </div>
                              </div>
                              {d.save_on_emi == 0 ||
                              isNaN(d.save_on_emi) ? null : (
                                <div
                                  className="fontSize-1"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    // color: " #009964",
                                    // paddingTop: "18px",
                                    // paddingLeft: "10px",
                                    // paddingBottom: "14px",
                                    // paddingRight: "10px",
                                    width: 140,
                                    height: 40,
                                    borderRadius: "100px",
                                    backgroundColor: " #0099641A",
                                    marginRight: "2px",
                                    marginLeft: "4px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    // textOverflow: "ellipsis",
                                    display: id == 3 ? "none" : "",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "rgb(0, 153, 100)",
                                  }}
                                >
                                  Saving ₹
                                  {formatNumberWithCommas(d.save_on_emi)}
                                </div>
                              )}
                            </div>
                            {id == "1" || id == "2" ? (
                              <Link
                                to="/details"
                                state={{
                                  oldIrr: d.oldIrr,
                                  newIrr: d.new_Irr,
                                  loanAmount: d.loanAmount,
                                  tenure: d.new_tenure,
                                  oldemi: d.old_emi,
                                  newemi: d.new_emi,
                                  saveonemi: d.save_on_emi,
                                  account_type: d.account_type,
                                  subscriber: d.subscriber_name,
                                  savepercentage: d.save_percentage,
                                  Account_Number: d.Account_Number,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    backgroundColor: "transparent",
                                    paddingTop: "10px",
                                    color: "#846CA6",
                                  }}
                                >
                                  Check Details
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to="/topupdetail"
                                state={{
                                  maxtopupallowed: d.max_topup_allowed,
                                  loan_tenure: d.loan_tenure,
                                  accountType: d.Account_Type,
                                  CurrentBalance: d.Current_Balance,
                                  Account_Number: d.Account_Number,
                                  subscriber: d.subscriber_name,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "13px",
                                    letterSpacing: "0em",
                                    backgroundColor: "transparent",
                                    paddingTop: "10px",
                                    color: "#846CA6",
                                  }}
                                >
                                  Check Details
                                </div>
                              </Link>
                            )}
                          </div>
                        ) : (
                          <div
                            className="content-box-1"
                            style={{
                              width: "358px",
                              height: "112px",
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              borderLeft: "1px solid #8A8A8A",
                              borderLeftWidth: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "transparent",
                                paddingLeft: "5px",
                                paddingTop: "20px",
                                paddingBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "12px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <div
                                  style={{
                                    width: "48px",
                                    height: "48px",
                                    borderRadius: "27px",
                                    backgroundColor: "rgba(85, 50, 133, 0.1)",
                                    textAlign: "center",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {d.account_type == "05" ? (
                                    <img
                                      src={userLogo}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        top: "7px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={HomeLogo}
                                      alt=""
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        top: "7px",
                                      }}
                                    />
                                  )}
                                </div>
                                <div style={{ backgroundColor: "transparent" }}>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      lineHeight: "15px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(31, 31, 31, 1)",
                                      paddingTop: "10px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.loan_name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      lineheight: "13px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                      color: "rgba(138, 138, 138, 1)",
                                      paddingTop: "5px",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {d.subscriber_name
                                      .split(" ")
                                      .slice(0, 2)
                                      .join(" ")}{" "}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  marginLeft: "100px",
                                  backgroundColor: "transparent",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "13px",
                                  letterSpacing: "0em",
                                  textAlign: "left",
                                  color: "#8A8A8A",
                                  alignSelf: "center",
                                }}
                              >
                                No saving opportunities{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            </div>
          )}
        </div>
        <div
          className="mobileScreen-121"
          style={{
            marginRight: "150px",
            display: id === "1" || id === 2 ? "" : "none",
          }}
        >
          <img
            className="mobileScreen12"
            src={Illustration}
            alt=""
            style={{
              width: "266px",
              height: "252px",
            }}
          />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "32px",
              letterSpacing: "0em",
              textAlign: "center",
            }}
          >
            You can save
            <br />
            <span
              style={{
                fontSize: "32px",
                fontWeight: "600",
                lineHeight: "32px",
                letterSpacing: "0em",
                color: "#59266D",
              }}
            >
              Rs {formatNumberWithCommas(state.Loan.emiSaved)}
            </span>
          </div>
        </div>
      </div>
      {id == "1" || id == "2" ? (
        <div
          style={{
            marginTop: "50px",
            marginBottom: "50px",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "15px",
              letterSpacing: "0em",
              color: "#8A8A8A",
            }}
          >
            1 selected
          </div>
          {accontType == "05" ? (
            <Link
              to="/homeloanform"
              state={{
                loanAmount: loanAmount,
                oldIrr: oldirr,
                tenure: tenure,
                newIrr: newirr,
                accountType: accontType,
                Account_Number: accountNumber,
              }}
            >
              <button
                className="button-Proceed"
                disabled={val === null}
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  opacity: val === null ? "39%" : "",
                  cursor: "pointer",
                  paddingTop: "19px",
                  paddingBottom: "19px",
                  paddingLeft: "146px",
                  paddingRight: "146px",
                  borderRadius: "10px",
                  marginTop: "10px",
                  backgroundColor: "#59266D",
                  border: "none",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                Proceed
              </button>
            </Link>
          ) : (
            <Link
              to="/homeloanform"
              state={{
                loanAmount: loanAmount,
                oldIrr: oldirr,
                tenure: tenure,
                newIrr: newirr,
                accountType: accontType,
                Account_Number: accountNumber,
              }}
            >
              <button
                className="button-Proceed"
                disabled={val === null}
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  cursor: "pointer",
                  opacity: val === null ? "39%" : "",
                  paddingTop: "19px",
                  paddingBottom: "19px",
                  paddingLeft: "146px",
                  paddingRight: "146px",
                  borderRadius: "10px",
                  marginTop: "10px",
                  backgroundColor: "#59266D",
                  border: "none",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                Proceed
              </button>
            </Link>
          )}
        </div>
      ) : (
        <div style={{ marginTop: "50px" }}></div>
      )}
    </div>
  );
}

export default LowerEmiDetailPage;
