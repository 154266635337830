import React, { useEffect, useRef } from "react";
import "./DownloadApp.css";
import Mobile from "../../Assets/Images/mobile.png";
import mLogo from "../../Assets/Images/mLogo.png";
import appleButton from "../../Assets/Images/appleButton.png";
import googleButton from "../../Assets/Images/googleButton.png";
import { Link } from "react-router-dom";

const DownloadApp = () => {
  const downloadAppRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#DownloadApp" && downloadAppRef.current) {
      downloadAppRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div ref={downloadAppRef} className="DownloadContainer" id="DownloadApp">
      <div className="DownloadHeading">
        <p>Download App</p>
        <h1>All in One place</h1>
      </div>
      <div className="DownloadSection">
        <div className="MobileImage">
          <img src={Mobile} alt="" />
        </div>
        <div className="downloadSubText">
          <p>
            Download Our App
            <img src={mLogo} alt="" />{" "}
            <p
              style={{
                fontWeight: 700,
                color: "rgba(89, 38, 109, 1)",
                paddingRight: "2px",
              }}
            >
              miniEMI
            </p>
            {"  "}
            for Lower EMIs and Enhanced
          </p>
          <p>Financial Services All in One Place!</p>

          <div
            style={{
              gap: "32px",
              display: "flex",
              backgroundColor: "transparent",
              marginTop: "26px",
            }}
          >
            <img
              src={googleButton}
              alt=""
              style={{
                width: "129px",
                height: "40px",
                mixBlendMode: "color-burn",
              }}
            />
            <Link
              to="https://apps.apple.com/in/app/minemi/id6477753054"
              style={{ backgroundColor: "transparent" }}
            >
              {" "}
              <img
                src={appleButton}
                alt=""
                style={{
                  width: "129px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="DownloadSectionMobile">
        <div style={{ backgroundColor: "transparent" }}>
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "rgba(132, 108, 166, 1)",
              fontWeight: 500,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Download Our App
            <img
              src={mLogo}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: "transparent",
              }}
            />
            miniEMI for Lower
          </p>
          <p
            style={{
              color: "rgba(132, 108, 166, 1)",
              fontWeight: 500,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            EMIs and Enhanced Financial Services All in One Place!
          </p>
        </div>

        <div className="MobileImage">
          <img src={Mobile} alt="" />
        </div>
        <div
          style={{
            gap: "32px",
            display: "flex",
            backgroundColor: "transparent",
            marginTop: "26px",
            marginBottom: "23px",
          }}
        >
          <img
            src={googleButton}
            alt=""
            style={{
              width: "129px",
              height: "40px",
              backgroundColor: "transparent",
            }}
          />
          <Link
            to="https://apps.apple.com/in/app/minemi/id6477753054"
            style={{ backgroundColor: "transparent" }}
          >
            <img
              src={appleButton}
              alt=""
              style={{
                width: "129px",
                height: "40px",
                backgroundColor: "transparent",
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
