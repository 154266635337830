import React, { useEffect, useState, useRef } from "react";
import zondicons_camera from "../../Assets/Images/zondicons_camera.png";
import "./UpdateProfile.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
function UpdateProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { name1, pan1, dob1, gender1, avatar1 } = location.state;
  const [name, setName] = useState(name1);
  const [avtar, setAvtar] = useState(avatar1);
  const [dob, setDob] = useState(dob1);
  const [gender, setGender] = useState(gender1);
  const [pan, setPan] = useState(pan1);
  const [image, setImage] = useState();
  const inputRef = useRef(null);
  const [progress, setprogress] = useState(0);
  const formattedDate = moment(dob).format("MMMM D, YYYY");
  const HandleImageClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    // Get the first file from the list of selected files
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage(file);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
  };

  const Logout = () => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");

    if (token === null) {
      navigate("/");
      window.location.reload();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first file from the list of selected files
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage(file);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
  };

  const formData = new FormData();
  formData.append("userImage", image);

  const UpdateProfileHandler = () => {
    setprogress(30);
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/profile/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    axios
      .request(options)
      .then(function (response) {
        toast(
          <div style={{ backgroundColor: "white" }}>{response.data.msg}</div>
        );
        // console.log(response.data);
        navigate("/dashboard");
        setprogress(100);
      })
      .catch(function (error) {
        // console.error(error.response.data.msg);
        toast(
          <div style={{ backgroundColor: "white" }}>
            {error.response.data.msg}
          </div>
        );
      });
  };
  // console.log(gender1);
  return (
    <>
      <LoadingBar color=" #59266d" progress={progress} />
      <ToastContainer style={{ backgroundColor: "transparent" }} />
      <div
        style={{
          backgroundColor: "rgba(85, 50, 133, 0.1)",
          width: "auto",
          height: "191px",
        }}
      ></div>
      <div
        className="profile-pic"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {image ? (
          <img
            className="rounded-circle"
            src={URL.createObjectURL(image)}
            style={{
              width: "120px",
              height: "120px",
              backgroundColor: "transparent",
              position: "absolute",
              top: "195px",
              borderRadius: "100%",
            }}
          />
        ) : (
          <img
            className="rounded-circle"
            src={avtar}
            style={{
              width: "120px",
              height: "120px",
              backgroundColor: "transparent",
              position: "absolute",
              top: "195px",
              borderRadius: "100%",
            }}
          />
        )}
        <img
          className=" zondicons-camera"
          src={zondicons_camera}
          alt=""
          style={{
            height: "32px",
            width: "32px",
            position: "relative",
            left: "58px",
            top: "5px",
            zIndex: "4",
            backgroundColor: "transparent",
          }}
          accept=".jpg,.jpeg,.png"
          onClick={HandleImageClick}
        />
        <input
          className=" zondicons-camera"
          type="file"
          ref={inputRef}
          accept=".jpg,.jpeg,.png"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <img
          className=" zondicons-camera2"
          src={zondicons_camera}
          alt=""
          style={{
            height: "32px",
            width: "32px",
            position: "relative",
            left: "100px",
            top: "10px",
            zIndex: "4",
            backgroundColor: "transparent",
          }}
          onClick={HandleImageClick}
        />
        <input
          className=" zondicons-camera2"
          type="file"
          ref={inputRef}
          accept=".jpg,.jpeg,.png"
          style={{ display: "none", borderWidth: 1 }}
          onChange={handleChange}
        />
      </div>
      <h1
        className="mobile-profile-name"
        style={{
          fontSize: "21px",
          fontWeight: "600",
          lineHeight: "21px",
          letterSpacing: "-0.02em",
          textAlign: "left",
          marginTop: "70px",
          marginLeft: "20px",
        }}
      >
        {name}
      </h1>
      <div
        className="profile-box"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "130px",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                paddingBottom: "10px",
              }}
            >
              Full name
            </p>
            <input
              className="profile-field"
              type="text"
              value={name}
              style={{
                padding: "12px, 231px, 12px, 16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "16px",
                paddingRight: "231px",
                borderRadius: "8px",
                border: "1px",
                border: "1px solid rgba(138, 138, 138, 1)",
                color: "background: rgba(138, 138, 138, 1)",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                paddingBottom: "10px",
              }}
            >
              Date of birth
            </p>
            <input
              className="profile-field"
              type="text"
              value={formattedDate}
              style={{
                padding: "12px, 231px, 12px, 16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "16px",
                paddingRight: "231px",
                borderRadius: "8px",
                border: "1px",
                border: "1px solid rgba(138, 138, 138, 1)",
                color: "background: rgba(138, 138, 138, 1)",
                outline: "none",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                paddingBottom: "10px",
              }}
            >
              Gender
            </p>
            <input
              className="profile-field"
              type="text"
              value={gender === "1" ? "Male" : gender == 2 ? "female" : null}
              style={{
                padding: "12px, 231px, 12px, 16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "16px",
                paddingRight: "231px",
                borderRadius: "8px",
                border: "1px",
                border: "1px solid rgba(138, 138, 138, 1)",
                color: "background: rgba(138, 138, 138, 1)",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                paddingBottom: "10px",
              }}
            >
              Pan Card Number
            </p>
            <input
              className="profile-field"
              type="text"
              value={pan}
              style={{
                padding: "12px, 231px, 12px, 16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "16px",
                paddingRight: "231px",
                borderRadius: "8px",
                border: "1px",
                border: "1px solid rgba(138, 138, 138, 1)",
                color: "background: rgba(138, 138, 138, 1)",
                outline: "none",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
          gap: "24px",
          marginBottom: "60px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <button
            className="profile-btn"
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              paddingTop: "19px",
              paddingBottom: "19px",
              paddingLeft: "146px",
              paddingRight: "146px",
              borderRadius: "10px",
              backgroundColor: "rgba(89, 38, 109, 1)",
              color: "rgba(255, 255, 255, 1)",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              UpdateProfileHandler();
            }}
          >
            Update Profile
          </button>
        </div>
        <div>
          <button
            className="profile-btn2"
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              paddingTop: "19px",
              paddingBottom: "19px",
              paddingLeft: "172px",
              paddingRight: "172px",
              borderRadius: "10px",
              border: "1px solid rgba(89, 38, 109, 1)",
              color: " rgba(89, 38, 109, 1)",
              cursor: "pointer",
            }}
            onClick={Logout}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
