import React, { useEffect, useState, useRef } from "react";
import otpimage from "../../Assets/Images/otp.png";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Otp.css";
import Footer from "../../Components/Footer/Footer";

const Otp = () => {
  const length = 6;
  const navigate = useNavigate();
  const location = useLocation();
  // const { from } = location.state;
  const { from } = location.state || {}; // Handle cases where location.state is undefined

  const mobile = localStorage.getItem("mobile");
  const stg1 = localStorage.getItem("stageone");
  const stg2 = localStorage.getItem("stagetwo");
  const [buttonEnable, setButton] = useState(false);
  const [progress, setProgress] = useState(0);
  const [otp, setOtp] = useState("");

  const inputRef = useRef(null);

  const [timeLeft, setTimeLeft] = useState(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);
    return timeLeft > 0 ? timeLeft : 30; // Default to 30 seconds if no valid time is stored
  });
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);
    if (timeLeft <= 0) {
      setIsResendDisabled(false);
    } else {
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, []);

  useEffect(() => {
    // When the component mounts, set the timer end time in localStorage
    const endTime = new Date().getTime() + timeLeft * 1000;
    localStorage.setItem("otpEndTime", endTime);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    // Ensure the input is numeric and limit to 6 characters
    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };

  const OtpValidate = () => {
    setButton(true);
    setProgress(30);
    // const otpValue = otp.join("");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
      headers: { Authorization: `Bearer ${from}` },
      data: {
        stgTwoHitId: stg2,
        stgOneHitId: stg1,
        otp: otp,
        type: "NORMAL",
        mobileNo: mobile,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);

        toast(
          <div style={{ backgroundColor: "white" }}>
            {response.data.status == 1 ? response.data.msg : response.data.msg}
          </div>
        );
        setProgress(100);
        if (response.data.status == 1) {
          localStorage.setItem("from", from);
          localStorage.setItem("name", response.data.data.name);
          // localStorage.setRemove("otpEndTime");

          navigate("/goal");
        } else {
          setOtp(new Array(length).fill(""));
          setButton(true);
        }
      })
      .catch(function (error) {
        toast(
          <div style={{ backgroundColor: "white" }}>
            {error.response.status == 500
              ? "Something went wrong"
              : error.response.data.msg}
          </div>
        );
        console.error(error);
        setProgress(100);
      });
  };

  const ResendOtp = () => {
    if (isResendDisabled) return;
    const name = localStorage.getItem("fname");
    const lastname = localStorage.getItem("lname");
    const mobile = localStorage.getItem("mobile");
    const email = localStorage.getItem("email");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/login`,
      data: {
        fname: name,
        mobile: mobile,
        email: email,
        lname: lastname,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status === 1) {
          // console.log(response.data);
          localStorage.setItem(
            "stageone",
            response.data.data.expData.stgOneHitId
          );
          localStorage.setItem(
            "stagetwo",
            response.data.data.expData.stgTwoHitId
          );
          setIsResendDisabled(true);
          setTimeLeft(30);
          localStorage.setItem("otpEndTime", new Date().getTime() + 30000);
          // setOtp(new Array(length).fill(""));
          setOtp("");
          toast(
            <div style={{ backgroundColor: "white" }}>{response.data.msg}</div>
          );
        } else {
          console.error("Failed to resend OTP");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    if (timeLeft === 0) {
      setIsResendDisabled(false);
    }
  }, [timeLeft]);
  useEffect(() => {
    if (timeLeft > 0) {
      setIsResendDisabled(true);
    }
  }, [timeLeft]);
  return (
    <div>
      <ToastContainer style={{ backgroundColor: "white" }} />
      <LoadingBar color=" #59266d" progress={progress} />
      <div className="otpFrame">
        <div className="otpContainer">
          <div className="otpImage">
            <img src={otpimage} alt="" />
          </div>

          <div className="otpFieldContainer">
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 21,
                  color: "rgba(89, 38, 109, 1)",
                  textAlign: "center",
                  paddingBottom: "12px",
                }}
              >
                Enter OTP
              </div>
              <div
                style={{
                  fontWeight: "500",
                  lineHeight: "14px",
                  fontSize: "14px",
                  color: "#8A8A8A",
                  textAlign: "center",
                }}
              >
                Please enter the one-time password (OTP) sent on the given
                number - {mobile}
              </div>
            </div>

            <div className="otpInputField">
              <input
                type="text"
                ref={inputRef}
                value={otp}
                onChange={handleChange}
                maxLength={6}
                style={{
                  // paddingTop: "19px",
                  paddingLeft: "130px",
                  // paddingBottom: "19px",
                  paddingRight: "130px",
                }}
              />
            </div>
            <div
              className="otpVerifyButton"
              style={{
                paddingTop: "19px",
                paddingLeft: "146px",
                paddingBottom: "19px",
                paddingRight: "146px",
                borderRadius: "10px",
                backgroundColor:
                  otp.length == 6 && buttonEnable == false
                    ? "rgb(89, 38, 109)"
                    : "gray",
                color: "white",
                cursor:
                  otp.length == 6 && buttonEnable ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                otp.length == 6
                  ? OtpValidate()
                  : toast(
                      <div style={{ backgroundColor: "white" }}>
                        Please enter a valid 6-digit OTP.
                      </div>
                    );
              }}
            >
              Verify
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
              }}
            >
              {formatTime(timeLeft)}
            </div>
            <div
              style={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                paddingBottom: "12px",
                cursor: isResendDisabled ? "not-allowed" : "pointer",
                opacity: isResendDisabled ? "0.5" : "1",
              }}
              onClick={ResendOtp}
            >
              Resend OTP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
