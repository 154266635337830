import React, { useEffect, useState } from "react";
import EmailContact from "../../Assets/Images/EmailContact.png";
import Call from "../../Assets/Images/Call.png";
import Address from "../../Assets/Images/Address.png";
import { useFormik } from "formik";
import "./ContactUs.css";
import { SignUpschemas } from "../../Schemas";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [message, setMessage] = useState("");
  const [viewmessage, setViewMessage] = useState(false);
  const [indicator, setIndicator] = useState(false);
  const [html, setHtmlContent] = useState("");
  const initialValues = {
    name: "",
    email: "",
  };
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: SignUpschemas,
      onSubmit: (values) => {
        // console.log(values);
      },
    });
  const handleLogin = () => {
    setViewMessage(true);
    if (values.name !== "" && values.email !== "" && message !== "") {
      setIndicator(true);
      Contactus();
    }
  };
  // console.log(values, message);
  const Contactus = () => {
    const options = {
      method: "POST",
      url: "https://app.minemi.ai/api/submit/contactForm",
      data: { name: values.name, email: values.email, message: message },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast(response.data.message);
        setIndicator(false);
        values.name = "";
        values.email = "";
        setMessage("");
        setViewMessage(false);
      })
      .catch(function (error) {
        console.error(error);
        setIndicator(false);
        setViewMessage(false);
      });
  };
  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <div className="container">
      <Helmet>
        <title>Contact us for App Support | Minemi</title>
        <meta
          name="description"
          content="Get assistance with using the Minemi.ai app for managing your loans, tracking EMI, and checking eligibility."
        />
      </Helmet>
      <ToastContainer />
      <div
        className="d-flex mainHeader marginHeader"
        style={{
          justifyContent: "space-between",
          paddingLeft: "30px",
          paddingRight: "150px",
          marginTop: "90px",
        }}
      >
        <div
          className="mainHeader heading1"
          style={{
            fontSize: "61px",
            fontWeight: "700",
            lineHeight: "66px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "rgba(31, 31, 31, 1)",
            marginBottom: "90px",
          }}
        >
          Get In Touch
          <br />
          With Us.
        </div>
        <div className="mainHeader" style={{ marginBottom: "90px" }}>
          <ul style={{ listStyle: "none" }}>
            <li style={{ marginBottom: "20px", display: "flex", gap: "16px" }}>
              <span>
                <img
                  src={EmailContact}
                  alt=""
                  style={{
                    width: "56px",
                    height: "56px",
                    padding: "19px, 17px, 19.89px, 17px",
                    borderRadius: "100px",
                  }}
                />
              </span>
              <div
                className="d-flex"
                style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              >
                <span
                  className="input-heading"
                  style={{
                    paddingTop: "10px",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "-0.02em",
                    color: "rgba(138, 138, 138, 1)",
                    textAlign: "left",
                  }}
                >
                  E-MAIL
                </span>
                <span
                  style={{
                    fontSize: "21px",
                    fontWeight: "600",
                    lineHeight: "25px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                  }}
                >
                  {html.email_text}
                </span>
              </div>
            </li>
            <li style={{ marginBottom: "20px", display: "flex", gap: "16px" }}>
              <span>
                <img
                  src={Call}
                  alt=""
                  style={{
                    width: "56px",
                    height: "56px",
                    padding: "19px, 17px, 19.89px, 17px",
                    borderRadius: "100px",
                  }}
                />
              </span>
              <div
                className="d-flex"
                style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              >
                <span
                  className="input-heading"
                  style={{
                    paddingTop: "10px",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    letterSpacing: "-0.02em",
                    color: "rgba(138, 138, 138, 1)",
                    textAlign: "left",
                  }}
                >
                  OPERATIONAL HOURS - 9:30 TO 6:30 PM (IST)
                </span>
                <span
                  style={{
                    fontSize: "21px",
                    fontWeight: "600",
                    lineHeight: "25px",
                    letterSpacing: "-0.02em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                  }}
                >
                  {html.contact_no_text}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
          <div className="borders"></div>
          <div style={{ marginTop: "20px", marginBottom: "35px" }}>
            <div
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                marginBottom: "20px",
              }}
            >
              What’s your name?
            </div>
            <div>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  fontSize: "21px",
                  fontWeight: "700",
                  lineHeight: "23px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "black",
                  outline: "none",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
          </div>

          <div className="borders"></div>
          {errors.name && touched.name ? (
            <p
              className="form-error "
              style={{ color: "red", marginTop: "4px" }}
            >
              {errors.name}
            </p>
          ) : null}
          <div style={{ marginTop: "20px", marginBottom: "35px" }}>
            <div
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                marginBottom: "20px",
              }}
            >
              What’s your email?
            </div>
            <div>
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  fontSize: "21px",
                  fontWeight: "700",
                  lineHeight: "23px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " 8A8A8A",
                  outline: "none",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="borders"></div>
          {errors.email && touched.email ? (
            <p
              className="form-error"
              style={{ color: "red", marginTop: "4px" }}
            >
              {errors.email}
            </p>
          ) : null}
          <div style={{ marginTop: "20px", marginBottom: "35px" }}>
            <div
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                marginBottom: "20px",
              }}
            >
              Your message
            </div>
            <div>
              <input
                type="text"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Type your message here."
                style={{
                  fontSize: "21px",
                  fontWeight: "700",
                  lineHeight: "23px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " 8A8A8A",
                  outline: "none",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="borders"></div>
          {viewmessage && message == "" && (
            <div style={{ color: "red", marginTop: "4px" }}>
              Message is Required
            </div>
          )}
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            onClick={handleLogin}
            className="contact-button"
            type="submit"
            style={{
              paddingTop: "19px",
              paddingLeft: "146px",
              paddingBottom: "19px",
              paddingRight: "146px",
              borderRadius: "10px",
              border: "none",
              backgroundColor: "rgba(89, 38, 109, 1)",
              color: "rgba(255, 255, 255, 1)",
              marginTop: "40px",
              marginBottom: "80px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
            }}
          >
            {indicator ? (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
