import React, { useState, useEffect } from "react";
import "./ProvideDetails.css";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import experian from "../../Assets/Images/experian.png";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const ProvideDetails = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [validationError2, setValidationError2] = useState("");
  const [validationError3, setValidationError3] = useState("");
  const [isclicked, setIsclicked] = useState(false);
  const [isclicked1, setIsclicked1] = useState(false);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const [companyname, setCompanyName] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [Company, setCompany] = useState("");
  const [progress, setprogress] = useState(0);
  const [value, setValue] = useState(null);
  const [searchElement, setSearchElement] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const from = localStorage.getItem("from");
    {
      from == null && navigate("/login");
    }
  }, []);

  const handleChange = (fieldName) => (value) => {
    setValidationError2("");
  };
  const values = { Profession: value };
  // console.log(values.Profession);

  const handleChange1 = (event) => {
    setSearchElement(event.target.value);
    handleSearch(searchElement);
    setOpen(true);
  };

  const handleSearch = (searchElement) => {
    const filteredResults = companyname.filter((item) =>
      item.company_name.toLowerCase().includes(searchElement.toLowerCase())
    );
    // console.log(filteredResults);
    setSearchResults(filteredResults);
  };

  const initialValues = {
    name: "",
    lastname: "",
    email: "",
    number: "",
  };

  const handleButtonClick = () => {
    setScrollDisabled(!scrollDisabled);
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setValidationError3("");
  };
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
    setValidationError3("");
  };
  const text =
    "dcnjsncjnsjcnjsdncjsncjnscjnsjcnsjcnsdjcnjdnnvjnfjvnajsncjndvjcvjndfjnviwnncnw";
  const [isReadMore, setIsReadMore] = useState(true);
  const [isReadMore2, setIsReadMore2] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const toggle2ReadMore = () => {
    setIsReadMore2(!isReadMore2);
  };

  const handleOptionSelect = (selectedValue, selectedName) => {
    setValue(selectedValue);
    setIsclicked(false);
    handleChange("Profession")(selectedValue);
    if (isclicked) {
      handleButtonClick();
    }
  };

  const handleOptionSelect2 = (companyname) => {
    setCompany(companyname);
    setValidationError("");
    setIsclicked1(false);
  };

  const checkValue = () => {
    let hasValidationError = false;

    if (values.Profession == null) {
      setValidationError2("Please select Profession type.");
      hasValidationError = true;
    } else {
      setValidationError2(""); // Resetting validation error if Profession type is selected
    }
    if (values.Profession === "1") {
      if (Company === "") {
        setValidationError("Please select valid company name.");
        hasValidationError = true;
      } else {
        setValidationError(""); // Clearing validation error if company name is selected
      }
    } else {
      setValidationError(""); // Clearing validation error if profession is not "Salaried"
    }

    if (!isChecked || !isChecked2) {
      setValidationError3("Please agree to the terms before proceeding.");
      hasValidationError = true;
    } else {
      setValidationError3(""); // Resetting validation error if terms are agreed
    }

    if (!hasValidationError) {
      fetchCibil();
    }
  };
  // console.log("djj", values.Profession == "1" && Company == null);
  // console.log(values.Profession, Company);

  const fetchCibil = () => {
    setprogress(30);
    const id = localStorage.getItem("id");
    const from = localStorage.getItem("from");
    // console.log(from);
    // console.log(values, Company, id);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/fetch_cibil`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        accept: "application/json",
        Authorization: `Bearer ${from}`,
      },
      data: { Profession: values, companyName: Company, goal: id },
    };
    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        localStorage.setItem("token", from);
        navigate("/dashboard");
        setprogress(100);
      })
      .catch(function (error) {
        // console.error("sdvcinsdi", error);
      });
  };
  const CompanyDetail = () => {
    const from = localStorage.getItem("from");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/companyList`,
    };

    axios
      .request(options)
      .then(function (response) {
        setCompanyName(response.data.data.company);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <div>
      <LoadingBar color=" #59266d" progress={progress} />
      <div className="provideDetailContainer">
        <div className="ProvideDetail">
          <div
            style={{
              // border: "1px solid blue",
              gap: "36px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: "rgba(31, 31, 31, 1)",
                }}
              >
                Great! Please share below details to
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: "rgba(89, 38, 109, 1)",
                }}
              >
                Lower Your EMIs
              </div>
            </div>

            <div
              style={{
                gap: "24px",
                display: "flex",
                flexDirection: "column",
                // border: "1px solid red",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: 500,
                  fontSize: "16px",
                  gap: "5px",
                }}
              >
                <div>
                  <span>Type of Profession</span>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div
                  className="input-boxes"
                  onClick={() => {
                    setIsclicked(!isclicked);
                  }}
                  style={{
                    width: "358px",
                    height: "48px",
                    paddingLeft: "8px",
                    borderRadius: "8px",
                    border: "1px solid rgba(132, 108, 166, 1)",
                    backgroundColor: "rgba(132, 108, 166, 0.1)",
                    cursor: "pointer", // Add cursor pointer for indicating clickable
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(31, 31, 31, 1)",
                  }}
                >
                  {values.Profession === "1" ? (
                    <div style={{ backgroundColor: "transparent" }}>
                      Salaried
                    </div>
                  ) : values.Profession === "2" ? (
                    <div style={{ backgroundColor: "transparent" }}>
                      Self-Employed
                    </div>
                  ) : values.Profession === "3" ? (
                    <div style={{ backgroundColor: "transparent" }}>Others</div>
                  ) : (
                    <div style={{ backgroundColor: "transparent" }}>
                      Select Profession
                    </div>
                  )}
                  <div style={{ backgroundColor: "transparent" }}>
                    {isclicked ? (
                      <FaChevronUp
                        size={16}
                        color={"#000000"}
                        style={{
                          backgroundColor: "transparent",
                          paddingRight: "10px",
                        }}
                      />
                    ) : (
                      <FaChevronDown
                        size={16}
                        color={"#000000"}
                        style={{
                          backgroundColor: "transparent",
                          paddingRight: "10px",
                        }}
                      />
                    )}
                  </div>
                </div>
                {isclicked ? (
                  <div
                    className="input-boxes"
                    style={{
                      // gap: "5px",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                      borderRadius: "8px",
                      border: "1px solid rgba(132, 108, 166, 1)",
                      paddingLeft: "8px",
                      backgroundColor: "rgba(132, 108, 166, 0.1)",
                      width: 358,
                    }}
                  >
                    <div
                      className="input-boxes"
                      style={{
                        width: "358px",
                        height: "48px",
                        // paddingLeft: "8px",
                        // borderRadius: "8px",
                        // border: "1px solid rgba(132, 108, 166, 1)",
                        // backgroundColor: "rgba(132, 108, 166, 0.1)",
                        cursor: "pointer", // Add cursor pointer for indicating clickable
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => handleOptionSelect("1", "Salaried")}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(31, 31, 31, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Salaried
                      </div>
                    </div>
                    <div
                      className="input-boxes"
                      style={{
                        width: "358px",
                        height: "48px",

                        backgroundColor: "transparent",
                        cursor: "pointer", // Add cursor pointer for indicating clickable
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => handleOptionSelect("2", "Self-Employed")}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(31, 31, 31, 1)",

                          backgroundColor: "transparent",
                        }}
                      >
                        Self-Employed
                      </div>
                    </div>
                    <div
                      className="input-boxes"
                      style={{
                        width: "358px",
                        height: "48px",

                        backgroundColor: "transparent",
                        cursor: "pointer", // Add cursor pointer for indicating clickable
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => handleOptionSelect("3", "Others")}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(31, 31, 31, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Others
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {validationError2 && (
                <div style={{ color: "red", marginTop: "-10px" }}>
                  {validationError2}
                </div>
              )}

              {value == 1 ? (
                <>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div>
                      <span>Company name</span>
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div
                      className="input-boxes"
                      onClick={() => {
                        setIsclicked1(!isclicked1);
                      }}
                      style={{
                        width: "358px",
                        height: "48px",
                        paddingLeft: "8px",
                        borderRadius: "8px",
                        border: "1px solid rgba(132, 108, 166, 1)",
                        backgroundColor: "rgba(132, 108, 166, 0.1)",
                        cursor: "pointer", // Add cursor pointer for indicating clickable
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(31, 31, 31, 1)",
                      }}
                    >
                      {Company === "" ? "Name of Company" : Company}

                      <div style={{ backgroundColor: "transparent" }}>
                        {isclicked1 ? (
                          <FaChevronUp
                            size={16}
                            color={"#000000"}
                            style={{
                              backgroundColor: "transparent",
                              paddingRight: "10px",
                            }}
                          />
                        ) : (
                          <FaChevronDown
                            size={16}
                            color={"#000000"}
                            style={{
                              backgroundColor: "transparent",
                              paddingRight: "10px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {isclicked1 ? (
                      <>
                        <div style={{ display: "flex", marginTop: "15px" }}>
                          <span
                            style={{
                              position: "absolute",
                              marginTop: "11px",
                              backgroundColor: "transparent",
                              marginLeft: "5px",
                            }}
                          >
                            <CiSearch
                              style={{
                                backgroundColor: "transparent",
                                fontSize: "18px",
                              }}
                            />
                          </span>
                          <input
                            className="search-fields-detail"
                            type="text"
                            placeholder="Search here"
                            onChange={handleChange1}
                            style={{
                              paddingTop: "12px",
                              paddingLeft: "25px",
                              paddingRight: "189px",
                              paddingBottom: "12px",
                              borderRadius: "8px",
                              border: "1px solid #8A8A8A",
                              backgroundColor: "#8A8A8A1A",
                            }}
                          />
                        </div>
                        {open && (
                          <div
                            className="input-boxes"
                            style={{
                              width: "358px",
                              paddingLeft: "4px",
                              paddingRight: "4px",
                              marginBottom: "10px",
                              paddingTop: "10px",
                              borderRadius: "8px",
                              border: "1px solid rgba(132, 108, 166, 1)",
                              backgroundColor: "rgba(132, 108, 166, 0.1)",
                              cursor: "pointer", // Add cursor pointer for indicating clickable
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "4%",
                            }}
                          >
                            {searchResults.map((d) => (
                              <div
                                onClick={() =>
                                  handleOptionSelect2(d.company_name)
                                }
                                style={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(31, 31, 31, 1)",
                                  backgroundColor: "transparent",
                                  paddingBottom: 20,
                                }}
                              >
                                {d.company_name}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {values.Profession == "1" ? (
                Company == null || Company == "" ? (
                  <div style={{ color: "red", marginTop: "-10px" }}>
                    {validationError}
                  </div>
                ) : null
              ) : null}
            </div>
            <form>
              <div className="CheckBoxContainer">
                <p className="text"></p>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    gap: "5px",
                    display: "flex",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ alignSelf: "flex-start" }}
                  />
                  {isReadMore ? (
                    <p>
                      {`I agree to MinEMI's `}
                      <Link
                        to="/terms&condition"
                        style={{
                          color: "rgba(89, 38, 109, 1)",
                          fontWeight: 600,
                        }}
                      >
                        Terms of Use
                      </Link>
                      {` and `}
                      <Link
                        to="/privacypolicy"
                        style={{
                          color: "rgba(89, 38, 109, 1)",
                          fontWeight: 600,
                        }}
                      >
                        Privacy Policy
                      </Link>
                      {`  and authorise to be communicated ... via SMS, Call, Whatsapp, and Email. I hereby also allow MinEMI to access my credit report on my behalf.`.slice(
                        0,
                        87
                      )}
                    </p>
                  ) : (
                    <p>
                      I agree to MinEMI's{" "}
                      <Link
                        to="/terms&condition"
                        style={{
                          color: "rgba(89, 38, 109, 1)",
                          fontWeight: 600,
                        }}
                      >
                        Terms of Use{" "}
                      </Link>
                      and
                      <Link
                        to="/privacypolicy"
                        style={{
                          color: "rgba(89, 38, 109, 1)",
                          fontWeight: 600,
                        }}
                      >
                        {" "}
                        Privacy Policy
                      </Link>{" "}
                      and authorise to be communicate via SMS ,Call, Whatsapp
                      and Email. I hereby also allow MInEMI to access my credit
                      report on my behalf{" "}
                    </p>
                  )}
                  <span
                    onClick={toggleReadMore}
                    className="read-or-hide"
                    style={{ color: "#59266D" }}
                  >
                    {isReadMore ? (
                      <div style={{ color: "#59266D" }}>...read more</div>
                    ) : (
                      " show less"
                    )}
                  </span>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    gap: "5px",
                    display: "flex",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked2}
                    onChange={handleCheckboxChange2}
                    style={{ alignSelf: "flex-start" }}
                  />
                  {/* {isReadMore2 ? (
                    "I allow Nine Triangles Pvt.Ltd to access my credit report from RBI approved credit bureaus on an ongoing basis for not exceeding 6 months. I also agree to..".slice(
                      0,
                      95
                    )
                  ) : ( */}
                    <p>
                      {" "}
                      I allow Nine Triangles Pvt.Ltd to access my credit report from RBI approved credit bureaus on an ongoing basis for not exceeding 6 months. I also agree to{" "}
                      <Link
                        to="/experianconsent"
                        style={{
                          color: "rgba(89, 38, 109, 1)",
                          fontWeight: 600,
                        }}
                      >
                        Experian Terms and Conditions.{" "}
                      </Link>
                    </p>
                  {/* )} */}
                  {/* <span
                    onClick={toggle2ReadMore}
                    className="read-or-hide"
                    style={{ color: "#59266D" }}
                  >
                    {isReadMore2 ? (
                      <div style={{ color: "#59266D" }}>...read more</div>
                    ) : (
                      " show less"
                    )}
                  </span> */}
                </div>
              </div>
            </form>
          </div>
          {validationError3 && (
            <div style={{ color: "red", marginTop: "-5%" }}>
              {validationError3}
            </div>
          )}

          <div className="SubContainer " style={{ marginTop: "30px" }}>
            <div className="experianLogo">
              <img src={experian} alt="" />
            </div>

            <button
              onClick={checkValue}
              style={{
                cursor: "pointer",
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvideDetails;
