import React from "react";
import "./OurServices.css";
import OurServiceLower from "../../Assets/Images/OurServiceLower.png";
import OurServiceConsolidate from "../../Assets/Images/OurServiceConsolidate.png";
import OurServiceTopUp from "../../Assets/Images/OurServiceTopUp.png";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.png";

import arrow from "../../Assets/Images/arrow.svg";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink as RouterHashLink } from "react-router-hash-link";

const OurServices = () => {
  return (
    <div>
      <div className="ServicesTitle">
        <p>Our Services</p>
        <h1>How would you like to save your money?</h1>
      </div>
      <div className="Cards">
        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1>Balance transfer</h1>
            <p>
              Take control of your finances with our smart solutions. Enjoy the
              freedom of lower monthly payments and save more with our optimized
              payment plans.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Lowest Interest Rates</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Boost Savings</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Flexible Terms</p>
            </div>
          </div>
          <RouterHashLink to="#DownloadApp" className="LowerEmiCta">
            <p>Get Started</p>
            <RouterHashLink
              to="#DownloadApp"
              style={{ backgroundColor: "transparent" }}
            >
              <img src={arrow} alt="" />
            </RouterHashLink>
          </RouterHashLink>
          <div className="OurServiceLower">
            <img src={OurServiceLower} alt="" />
          </div>
        </div>
        <div className="TwoCard">
          <div className="CardOne">
            <div style={{ backgroundColor: "transparent", width: "340px" }}>
              <div className="ConsolidateCardHeading">
                <h1>Loan Consolidation</h1>
                <p>
                  Streamline your finances with our one-stop EMI solution.
                  Experience hassle-free management of your loans with a single
                  monthly payment.
                </p>
              </div>
              <div className="ConsolidatePoints">
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Single EMI</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Savings Growth</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Flexible Options</p>
                </div>
              </div>
              <RouterHashLink className="LowerEmiCta" to="#DownloadApp">
                <p>Get Started</p>
                <RouterHashLink
                  to="#DownloadApp"
                  style={{ backgroundColor: "transparent" }}
                >
                  <img src={arrow} alt="" />
                </RouterHashLink>
              </RouterHashLink>
            </div>

            <div className="OurServiceConsolidate">
              <img src={OurServiceConsolidate} alt="" />
            </div>
          </div>

          <div className="CardTwo">
            <div style={{ backgroundColor: "transparent", width: "340px" }}>
              <div className="ConsolidateCardHeading">
                <h1>Top-up Running Loan</h1>
                <p>
                  Unlock the full potential of your financial resources with our
                  easy loan top-up service. Increase your loan amount
                  effortlessly and enjoy greater financial flexibility.
                </p>
              </div>
              <div className="ConsolidatePoints">
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Single EMI</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Savings Growth</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Flexible Options</p>
                </div>
              </div>
              <RouterHashLink to="#DownloadApp" className="LowerEmiCta" >
                <p style={{marginTop:'-7%'}}>Get Started</p>
                <RouterHashLink
                  to="#DownloadApp"
                  style={{ backgroundColor: "transparent",marginTop:'-7%' }}
                >
                  <img src={arrow} alt="" />
                </RouterHashLink>
              </RouterHashLink>
            </div>

            <div className="OurServiceTopUp">
              <img src={OurServiceTopUp} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="CardMobile">
        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1 style={{marginBottom:'2%'}}>Balance transfer</h1>
            <p>
              Take control of your finances with our smart solutions. Enjoy the
              freedom of lower monthly payments and save more with our optimized
              payment plans.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Lowest Interest Rates</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Boost Savings</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Flexible Terms</p>
            </div>
          </div>
          <Link to="/login" className="LowerEmiCta" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <p>Get Started</p>
            <Link to="/login" style={{ backgroundColor: "transparent" }} onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
              <img src={arrow} alt="" />
            </Link>
          </Link>
          <div className="OurServiceLower">
            <img src={OurServiceLower} alt="" />
          </div>
        </div>
        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1 style={{marginBottom:'2%'}}>Loan Consolidation</h1>
            <p>
              Streamline your finances with our one-stop EMI solution.
              Experience hassle-free management of your loans with a single
              monthly payment.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Single EMI</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Savings Growth</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Flexible Options</p>
            </div>
          </div>
          <Link to="/login" className="LowerEmiCta" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <p>Get Started</p>
            <Link to="/login" style={{ backgroundColor: "transparent" }} onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
              <img src={arrow} alt="" />
            </Link>
          </Link>
          <div className="OurServiceLower">
            <img src={OurServiceConsolidate} alt="" />
          </div>
        </div>
        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1 style={{marginBottom:'2%'}}>Top-up Running Loan</h1>
            <p>
              Unlock the full potential of your financial resources with our
              easy loan top-up service. Increase your loan amount effortlessly
              and enjoy greater financial flexibility.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>East access to additional funds</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Maximize Savings</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Diverse Financial Solutions</p>
            </div>
          </div>
          <Link to="/login" className="LowerEmiCta" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <p>Get Started</p>
            <Link to="/login" style={{ backgroundColor: "transparent" }} onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
              <img src={arrow} alt="" />
            </Link>
          </Link>
          <div className="OurServiceLower">
            <img src={TopUPIllustration} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
