import React, { useState, useEffect } from "react";
import HomeLogo from "../../Assets/Images/HomeLogo.png";
import { FaCheck } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa6";
import "./LoanEdit.css";
import userLogo from "../../Assets/Images/userLogo.png";
import { FaChevronUp } from "react-icons/fa6";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import check from "../../Assets/Images/check.png";
import uncheck from "../../Assets/Images/uncheck.png";

function LoanEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [first, setFirst] = useState(state.LoanDetail);
  const [open, setOpen] = useState(false);
  const id = localStorage.getItem("id");
  // console.log(id);

  const handleInputChange = (index, field, value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    // Check for leading zeros and empty values
    const isValid = numericValue.length > 0 && !numericValue.startsWith("0");

    const updatedLoanDetails = first.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: numericValue };
      }
      return item;
    });

    setFirst(updatedLoanDetails);

    setInputEmpty((prevInputEmpty) => ({
      ...prevInputEmpty,
      [`${index}_${field}`]: !isValid,
    }));
  };

  const [inputEmpty, setInputEmpty] = useState(() => {
    const initialInputEmpty = {};

    first.forEach((item, index) => {
      if (
        item.Current_Balance === "" ||
        item.Repayment_Tenure === "" ||
        item.EMI_Amount === ""
      ) {
        initialInputEmpty[`${index}_Current_Balance`] =
          item.Current_Balance === "";
        initialInputEmpty[`${index}_Repayment_Tenure`] =
          item.Repayment_Tenure === "";
        initialInputEmpty[`${index}_EMI_Amount`] = item.EMI_Amount === "";
      }
    });

    return initialInputEmpty;
  });

  const handleClick = (index) => {
    setOpen((prev) => (prev === index ? null : index));
  };

  const HandleResponse = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    const filledLoanDetails = first.filter(
      (data) =>
        data.Current_Balance !== "" &&
        data.Repayment_Tenure !== "" &&
        data.EMI_Amount !== ""
    );

    const formattedData = filledLoanDetails.map((data) => ({
      amt: data.Current_Balance,
      tenure: data.Repayment_Tenure,
      emi: data.EMI_Amount,
      Account_Number: data.Account_Number,
      loan_name: data.loan_Category,
      account_type: data.Account_Type,
      Subscriber_Name: data.Subscriber_Name,
    }));

    const loanDetail = {};
    formattedData.forEach((data, index) => {
      loanDetail[
        index + 1
      ] = `['amt'=>${data.amt}, 'tenure'=>${data.tenure}, 'emi'=>${data.emi}, 'Account_Number'=>'${data.Account_Number}', 'loan_name'=>'${data.loan_name}', 'account_type'=>'${data.account_type}','Subscriber_Name'=>'${data.Subscriber_Name}']`;
    });

    const dataToSend = { loanDetail };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/submit_loan_detail/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log("d", response.data);
        if (response.data.status == 1) {
          // console.log("response from the api", response.data);
          navigate("/savingopportunity", {
            state: { Loan: response.data.data },
          });
          // console.log("consoling", response.data.data);
        } else {
          toast(response.data.msg);
        }
      })
      .catch(function (error) {
        // console.error(error);
        toast("Please Enter Valid Detail");
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  // const HandleResponse = () => {
  //   const token = localStorage.getItem("token");
  //   const id = localStorage.getItem("id");
  //   const newData = first.map(
  //     ({
  //       EMI_Amount,
  //       Current_Balance,
  //       Repayment_Tenure,
  //       Account_Number,
  //       Account_Type,
  //       Subscriber_Name,
  //       loan_Category,
  //     }) => ({
  //       EMI_Amount,
  //       Current_Balance,
  //       Repayment_Tenure,
  //       Account_Number,
  //       Account_Type,
  //       Subscriber_Name,
  //       loan_Category,
  //     })
  //   );

  //   const formattedData = newData.map((data) => ({
  //     amt: data.Current_Balance,
  //     tenure: data.Repayment_Tenure,
  //     emi: data.EMI_Amount,
  //     Account_Number: data.Account_Number,
  //     loan_name: data.loan_Category,
  //     account_type: data.Account_Type,
  //     Subscriber_Name: data.Subscriber_Name,
  //   }));

  //   const loanDetail = {};
  //   formattedData.forEach((data, index) => {
  //     loanDetail[
  //       index + 1
  //     ] = `['amt'=>${data.amt}, 'tenure'=>${data.tenure}, 'emi'=>${data.emi}, 'Account_Number'=>'${data.Account_Number}', 'loan_name'=>'${data.loan_name}', 'account_type'=>'${data.account_type}','Subscriber_Name'=>'${data.Subscriber_Name}']`;
  //   });

  //   const dataToSend = { loanDetail };

  //   const filledLoanDetails = first.filter((data) =>
  //   data.Current_Balance !== "" &&
  //   data.Repayment_Tenure !== "" &&
  //   data.EMI_Amount !== ""
  // );

  //   const options = {
  //     method: "POST",
  //     url: `${process.env.REACT_APP_SECRET_KEY}/submit_loan_detail/${id}`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   };
  //   axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log("response from the api", response.data);
  //       navigate("/savingopportunity", {
  //         state: { Loan: response.data.data },
  //       });
  //       console.log(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // };
  return (
    <div style={{ marginTop: "90px" }}>
      <ToastContainer style={{ backgroundColor: "white" }} />

      <div className="loan-edit-box container " style={{ marginLeft: "150px" }}>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "17px",
            letterSpacing: "0em",
            color: "#1F1F1F",
          }}
        >
          Please, confirm below details to know exact
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "35px",
              letterSpacing: "0em",
              color: "#59266D",
            }}
          >
            saving opportunities
          </div>
        </div>
      </div>

      <>
        <div
          className="main-loanEdit-box"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "24px",
            flexWrap: "wrap",
            marginTop: "40px",
            alignContent: "center",
            marginLeft: "60px",
          }}
        >
          {first?.map((d, index) => {
            const isCategoryOpen = open === index;
            return (
              <>
                <div
                  key={index}
                  className="content-box-1"
                  style={{
                    width: "390px",
                    borderRadius: "10px",
                    backgroundColor: "#FFFFFF",
                    height: isCategoryOpen ? "auto" : "80px", // Set a fixed height for closed boxes
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "transparent",
                      paddingLeft: "10px",
                      paddingTop: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "12px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          src={
                            inputEmpty[`${index}_Current_Balance`] ||
                            inputEmpty[`${index}_Repayment_Tenure`] ||
                            inputEmpty[`${index}_EMI_Amount`]
                              ? uncheck
                              : check
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: "48px",
                          height: "48px",
                          borderRadius: "27px",
                          backgroundColor: "rgba(85, 50, 133, 0.1)",
                          textAlign: "center",
                        }}
                      >
                        {d.Account_Type === "05" ? (
                          <img
                            src={userLogo}
                            alt=""
                            style={{
                              width: "32px",
                              height: "32px",
                              backgroundColor: "transparent",
                              position: "relative",
                              top: "7px",
                            }}
                          />
                        ) : (
                          <img
                            src={HomeLogo}
                            alt=""
                            style={{
                              width: "32px",
                              height: "32px",
                              backgroundColor: "transparent",
                              position: "relative",
                              top: "7px",
                            }}
                          />
                        )}
                      </div>
                      <div style={{ backgroundColor: "transparent" }}>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "15px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(31, 31, 31, 1)",
                            paddingTop: "10px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {d.loan_Category}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            lineheight: "13px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(138, 138, 138, 1)",
                            paddingTop: "5px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {d.Subscriber_Name.toUpperCase()}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleClick(index)}
                      style={{
                        backgroundColor: "transparent",
                        marginRight: "20px",
                        marginTop: "10px",
                      }}
                    >
                      {open === index ? (
                        <FaChevronDown
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                          }}
                        />
                      ) : (
                        <FaChevronUp
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {isCategoryOpen && (
                    <div
                      style={{
                        paddingTop: "20px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "white",
                        marginBottom: "40px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "transparent",
                          margin: " 0 auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "transparent",
                            paddingBottom: "10px",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "17px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          Outstanding
                        </div>
                        <input
                          className="loan-edit-field"
                          type="text"
                          placeholder="0,00,000"
                          value={d.Current_Balance}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Current_Balance",
                              e.target.value
                            )
                          }
                          style={{
                            width: "326px",
                            height: "48px",
                            borderRadius: "8px",
                            border: "1px solid #8A8A8A",
                            paddingLeft: "10px",
                            outline: "none",
                            backgroundColor: inputEmpty[
                              `${index}_Current_Balance`
                            ]
                              ? "rgba(255, 0, 0, 0.1)"
                              : "#00C7810D",
                            borderColor: inputEmpty[`${index}_Current_Balance`]
                              ? "#EE393966"
                              : " #00C781",
                            marginBottom: "20px",
                          }}
                        />
                        <div
                          style={{
                            color: "black",
                            fontSize: "16px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {inputEmpty[`${index}_Current_Balance`] ? (
                            <div
                              style={{
                                color: "red",
                                backgroundColor: "transparent",
                                marginTop: "-14px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "15px",
                                letterSpacing: "0em",
                                textAlign: "left",
                              }}
                            >
                              Please Enter Valid Details
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "transparent",
                          margin: " 0 auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "transparent",
                            paddingBottom: "10px",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "17px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          Tenure(Month)
                        </div>
                        <input
                          className="loan-edit-field"
                          type="text"
                          placeholder="0,00,000"
                          value={d.Repayment_Tenure}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Repayment_Tenure",
                              e.target.value
                            )
                          }
                          style={{
                            width: "326px",
                            height: "48px",
                            borderRadius: "8px",
                            border: "1px solid #8A8A8A",
                            paddingLeft: "10px",
                            outline: "none",
                            // marginBottom: "20px",
                            backgroundColor: inputEmpty[
                              `${index}_Repayment_Tenure`
                            ]
                              ? "rgba(255, 0, 0, 0.1)"
                              : "#00C7810D",
                            borderColor: inputEmpty[`${index}_Repayment_Tenure`]
                              ? "#EE393966"
                              : " #00C781",
                          }}
                        />
                        <div
                          style={{
                            color: "black",
                            fontSize: "16px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {inputEmpty[`${index}_Repayment_Tenure`] ? (
                            <div
                              style={{
                                color: "red",
                                backgroundColor: "transparent",
                                marginTop: "5px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "15px",
                                letterSpacing: "0em",
                                textAlign: "left",
                              }}
                            >
                              Please Enter Valid Details
                            </div>
                          ) : null}
                                   
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "transparent",
                          margin: " 0 auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "transparent",
                            paddingBottom: "10px",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "17px",
                            letterSpacing: "0em",
                            textAlign: "left",
                          }}
                        >
                          EMI
                        </div>
                        <input
                          className="loan-edit-field"
                          type="text"
                          placeholder="0,00,000"
                          value={d.EMI_Amount}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "EMI_Amount",
                              e.target.value
                            )
                          }
                          style={{
                            width: "326px",
                            height: "48px",
                            borderRadius: "8px",
                            border: "1px solid #8A8A8A",
                            paddingLeft: "10px",
                            outline: "none",
                            backgroundColor: "#8A8A8A0D",
                            marginBottom: "23px",
                            backgroundColor: inputEmpty[`${index}_EMI_Amount`]
                              ? "rgba(255, 0, 0, 0.1)"
                              : "#00C7810D",
                            borderColor: inputEmpty[`${index}_EMI_Amount`]
                              ? "#EE393966"
                              : " #00C781",
                          }}
                        />
                        <div
                          style={{
                            color: "black",
                            fontSize: "16px",
                            backgroundColor: "transparent",
                          }}
                        >
                          {inputEmpty[`${index}_EMI_Amount`] ? (
                            <div
                              style={{
                                color: "red",
                                backgroundColor: "transparent",
                                marginTop: "-14px",
                                marginBottom: "20px",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "15px",
                                letterSpacing: "0em",
                                textAlign: "left",
                              }}
                            >
                              Please Enter Valid Details
                            </div>
                          ) : null}
                                   
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "60px",
          }}
        >
          <button
            className=" loan-edit-field"
            onClick={() => {
              HandleResponse();
            }}
            style={{
              borderRadius: "10px",
              color: "#FFFFFF",
              backgroundColor: "#59266D",
              fontSize: "16px",
              border: "none",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              cursor: "pointer",
              width: "390px",
              height: "50px",
            }}
          >
            Confirm & proceed
          </button>
        </div>
      </>
    </div>
  );
}

export default LoanEdit;
