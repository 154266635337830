import React, { useEffect, useState } from "react";
import "./BlogList.css"; // Importing the CSS for styling
import { Link } from "react-router-dom";
import "./BlogDetail.css"; // Importing the CSS for styling
import axios from "axios";
import { useLocation } from "react-router-dom";

function BlogDetail() {
  const [blogDetail, setBlogDetail] = useState();
  const [blog, setBlog] = useState([]);
  const location = useLocation();
  const { bslug } = location.state || {};

  const blogHandler = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    console.log(token);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/blog`,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.view_blog);
        setBlog(response.data.data.view_blog);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BlogDetailHandler = () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/blog_detail`,
      data: { bslug: bslug },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.detail[0]);
        setBlogDetail(response.data.data.detail[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BlogDetailHandler();
    blogHandler();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="blog-detail-container">
      {/* Main blog content */}
      <div className="blog-main-content">
        <h1 className="blog-title">{blogDetail?.title}</h1>
        <p className="published-date">
          Published on: {formatDate(blogDetail?.created_at)}
        </p>
        <img
          src={
            blogDetail ? blogDetail?.image : "https://via.placeholder.com/820x340"
          }
          alt="Blog Banner"
          className="blog-banner"
        />
        <div className="blog-description">
          <div dangerouslySetInnerHTML={{ __html: blogDetail?.description }} />
          {/* <p>{blogDetail?.description}</p> */}
        </div>
      </div>

      {/* Related blogs on the side */}
      <div className="related-blogs">
        <h2 className="related-title">Related Blogs</h2>
        {blog.slice(0, 3)?.map((blog) => (
          <div className="related-blog-item" key={blog.id}>
            <img
              src={blog.image}
              alt={blog.title}
              className="related-blog-image"
            />
            <p className="related-blog-title">{blog.title}</p>
            <div
              style={{ backgroundColor: "#FFFFFF" }}
              className="related-blog-discription"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            />

            {/* <p className="related-blog-discription">{blog.description}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogDetail;
